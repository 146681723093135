import axios, { AxiosRequestConfig } from "axios";
import { ParsedUrlQueryInput } from "querystring";

type TMethod = "get" | "post" | "patch" | "put" | "delete";

interface IData {
  [key: string]:
  | null
  | number
  | string
  | boolean
  | IData
  | Array<null | number | string | boolean | IData>;
}

const baseUrl: string = `${process.env.REACT_APP_BASE_API_URL}`;

const clear = (str: string) => str.trim().replace(/(^\/)|(\/$)/g, "");

interface IApiOptions {
  baseUrl?: string;
}

const api = (prefix: string, options?: IApiOptions) =>
  async (uri: string = "/", method: TMethod = "get", query?: ParsedUrlQueryInput, data?: IData) => {
    const path = uri === "/" || !uri ? "" : `/${clear(uri)}`;
    const url = `${options ? options.baseUrl : baseUrl}/${clear(prefix)}${path}`;
    const token = localStorage.getItem("accessToken");
    const authorization = token ? `Bearer ${token}` : null;
    const config: AxiosRequestConfig = {
      url,
      method,
      data,
      params: query || {},
    };
    axios.interceptors.request.use(request => {
      if (request?.headers) {
        if (authorization) {
          request.headers.Authorization = authorization;
        }
        request.headers.accept = 'application/json';
      }
      return request;
    });
    try {
      const res = await axios(config);
      return res.data;
    } catch (e: any) {
      console.log('axios', config, e);
      if (e.response?.status === 401 || e.response?.status === 403) {
        localStorage.removeItem("idToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = `/signin?error=${e.response.status}&callback_url=${window.location.pathname}`;
      }
    }
  };

// const refreshToken = () => {
//   const config: AxiosRequestConfig = {
//     url: `${baseUrl}/auth`,
//     method: 'PUT',
//     data: { refresh_token: localStorage.getItem("refreshToken") },
//   };
//   axios(config).then(res => {
//     localStorage.setItem("idToken", res.data.id_token);
//     localStorage.setItem("accessToken", res.data.access_token);
//     localStorage.setItem("refreshToken", res.data.refresh_token);
//   });
// }

export default api;
