export const GetBalanceResponse = `{
  "addresses": [
    "0x00000000219ab540356cbb839cbe05303d7705fa"
  ],
  "totalBalance": 2006611306745175600,
  "totalBalanceUSD": 37857.72287769323,
  "networksTotal": [
    {
      "name": "ethereum",
      "balance": 353789631294.7772,
      "balanceUSD": 37528.478238485404
    },
    {
      "name": "zksync",
      "balance": 0.120032,
      "balanceUSD": 0.158812739072
    },
    {
      "name": "bsc",
      "balance": 6610926582192362,
      "balanceUSD": 327.7557412100879
    },
    {
      "name": "polygon",
      "balance": 2000000026373052000,
      "balanceUSD": 1.3300852586650913
    },
    {
      "name": "avalanche",
      "balance": 300000,
      "balanceUSD": 0
    }
  ],
  "protocolsTotal": [
    {
      "name": "tokens",
      "balance": 2006611306745175600,
      "balanceUSD": 37801.60479824904
    },
    {
      "name": "aave",
      "balance": 1,
      "balanceUSD": 52.72
    },
    {
      "name": "Balancer",
      "balance": 0.3648127320626142,
      "balanceUSD": 1.9444518618937339
    },
    {
      "name": "zksync",
      "balance": 0.120032,
      "balanceUSD": 0.158812739072
    },
    {
      "name": "aave-v2",
      "balance": 0.001067784349968239,
      "balanceUSD": 1.2948148432250912
    }
  ],
  "categoriesTotal": [
    {
      "name": "wallet",
      "balance": 2006611306745175600,
      "balanceUSD": 37857.72287769323
    }
  ],
  "networks": [
    "ethereum",
    "zksync",
    "bsc",
    "polygon",
    "avalanche"
  ],
  "categories": [
    {
      "name": "fiat",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "exchange",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "offchain",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "wallet",
      "totalBalance": 37857.72287769323,
      "networks": [
        {
          "name": "ethereum",
          "data": [
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "USDT",
              "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
              "source": "ALZFT",
              "label": "Tether",
              "network": "ethereum",
              "balanceRaw": "0x000000000000000000000000000000000000000000000000000000012614a062",
              "protocol": "tokens",
              "balance": 4933.853282,
              "balanceUSD": 4933.853282,
              "price": 1,
              "decimals": 6,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "USDC",
              "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
              "source": "ALZFT",
              "label": "USD Coin",
              "network": "ethereum",
              "balanceRaw": "0x00000000000000000000000000000000000000000000000000000000000186a0",
              "protocol": "tokens",
              "balance": 0.1,
              "balanceUSD": 0.1,
              "price": 1,
              "decimals": 6,
              "imageUrl": "https://logos.covalenthq.com/tokens/1/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SHIB",
              "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
              "source": "ALZFT",
              "label": "Shiba Inu",
              "network": "ethereum",
              "balanceRaw": "0x00000000000000000000000000000000000000000000152d02c7e14af6800000",
              "protocol": "tokens",
              "balance": 99999.99999999999,
              "balanceUSD": 0.8119999999999999,
              "price": 0.00000812,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AAVE",
              "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
              "source": "ALZFT",
              "label": "Aave",
              "network": "ethereum",
              "balanceRaw": "0x0000000000000000000000000000000000000000000000000de0b6b3a7640000",
              "protocol": "aave",
              "balance": 1,
              "balanceUSD": 52.72,
              "price": 52.72,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LRC",
              "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
              "source": "ALZFT",
              "label": "Loopring",
              "network": "ethereum",
              "balanceRaw": "0x0000000000000000000000000000000000000000000000068f365aea1e440000",
              "protocol": "tokens",
              "balance": 121,
              "balanceUSD": 23.368125,
              "price": 0.193125,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SLP",
              "address": "0x37236cd05b34cc79d3715af2383e96dd7443dcf1",
              "source": "ALZFT",
              "label": "Small Love Potion",
              "network": "ethereum",
              "balanceRaw": "0x00000000000000000000000000000000000000000000000000000000000003e8",
              "protocol": "tokens",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MKR",
              "address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
              "source": "ALZFT",
              "label": "Maker",
              "network": "ethereum",
              "balanceRaw": "0x000000000000000000000000000000000000000000000000001f469e4dad2400",
              "protocol": "tokens",
              "balance": 0.00880337,
              "balanceUSD": 4.4469343218,
              "price": 505.14,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OMG",
              "address": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
              "source": "ALZFT",
              "label": "OMG Network",
              "network": "ethereum",
              "balanceRaw": "0x0000000000000000000000000000000000000000000000000c7d713b49da0000",
              "protocol": "tokens",
              "balance": 0.9,
              "balanceUSD": 0.9440999999999999,
              "price": 1.049,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "COMP",
              "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
              "source": "ALZFT",
              "label": "Compound",
              "network": "ethereum",
              "balanceRaw": "0x0000000000000000000000000000000000000000000000000095969a0f6c022d",
              "protocol": "tokens",
              "balance": 0.04210535995775442,
              "balanceUSD": 1.3120030162836278,
              "price": 31.16,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xc00e94cb662c3520282e6f5717214004a7f26888.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "REQ",
              "address": "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
              "source": "ALZFT",
              "label": "Request",
              "network": "ethereum",
              "balanceRaw": "0x000000000000000000000000000000000000000000000000000e35fa931a0000",
              "protocol": "tokens",
              "balance": 0.004,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HEX",
              "address": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
              "source": "ALZFT",
              "label": "HEX",
              "network": "ethereum",
              "balanceRaw": "0x0000000000000000000000000000000000000000000000000000328705d7c300",
              "protocol": "tokens",
              "balance": 555555,
              "balanceUSD": 9946.3789425,
              "price": 0.0179035,
              "decimals": 8,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:47.086Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "STAKE",
              "address": "0x0ae055097c6d159879521c384f1d2123d1f195e6",
              "source": "CVQKM",
              "label": "STAKE",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "40000000000000000000000",
              "balance": 40000,
              "balanceUSD": 20619.28,
              "price": 0.515482,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RES",
              "address": "0x0a9f693fce6f00a51a8e0db4351b5a8078b4242e",
              "source": "CVQKM",
              "label": "Resfinex",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "5000000000",
              "balance": 50000,
              "balanceUSD": 744.2315,
              "price": 0.01488463,
              "decimals": 5,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "eMTRG",
              "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
              "source": "CVQKM",
              "label": "Meter Governance mapped by Meter.io",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 202,
              "price": 2.02,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "POLY",
              "address": "0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec",
              "source": "CVQKM",
              "label": "Polymath",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1337000000000000000000",
              "balance": 1337,
              "balanceUSD": 194.599013,
              "price": 0.145549,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETH2SOCKS",
              "address": "0xd80776bbf68cde466900a7214c772a7d179c420f",
              "source": "CVQKM",
              "label": "ETH2SOCKS",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 193.43,
              "price": 193.43,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETHY",
              "address": "0xd1afbccc9a2c2187ea544363b986ea0ab6ef08b5",
              "source": "CVQKM",
              "label": "Ethereum Yield",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 139,
              "price": 1.39,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MEME",
              "address": "0xd5525d397898e5502075ea5e830d8914f6f0affe",
              "source": "CVQKM",
              "label": "MEME",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "200000000",
              "balance": 2,
              "balanceUSD": 84.86,
              "price": 42.43,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OOKS",
              "address": "0x69d9905b2e5f6f5433212b7f3c954433f23c1572",
              "source": "CVQKM",
              "label": "Onooks",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "405151465488000000000",
              "balance": 405.15146548800004,
              "balanceUSD": 78.79021064491685,
              "price": 0.194471,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LCX",
              "address": "0x037a54aab062628c9bbae1fdb1583c195585fe41",
              "source": "CVQKM",
              "label": "LCX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2020011220200000000000",
              "balance": 2020.0112202,
              "balanceUSD": 66.96690696926535,
              "price": 0.03315175,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PXP",
              "address": "0x95aa5d2dbd3c16ee3fdea82d5c6ec3e38ce3314f",
              "source": "CVQKM",
              "label": " PointPay Crypto Banking Token V2 ",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "7225000000000000000000",
              "balance": 7225,
              "balanceUSD": 64.72971425,
              "price": 0.00895913,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DPI",
              "address": "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
              "source": "CVQKM",
              "label": "DefiPulse Index",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 58.41,
              "price": 58.41,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SHROOM",
              "address": "0xed0439eacf4c4965ae4613d77a5c2efe10e5f183",
              "source": "CVQKM",
              "label": "shroom.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1337000000000000000000",
              "balance": 1337,
              "balanceUSD": 36.94093564,
              "price": 0.02762972,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MVDG",
              "address": "0x2ee543b8866f46cc3dc93224c6742a8911a59750",
              "source": "CVQKM",
              "label": "Metaverse Dog",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "25000000000000000000000",
              "balance": 24999.999999999996,
              "balanceUSD": 25.995999999999995,
              "price": 0.00103984,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "0xBTC",
              "address": "0xb6ed7644c69416d67b522e20bc294a9a9b405b31",
              "source": "CVQKM",
              "label": "0xBitcoin Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "8414380243",
              "balance": 84.14380243,
              "balanceUSD": 15.48119749008355,
              "price": 0.183985,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BOR",
              "address": "0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9",
              "source": "CVQKM",
              "label": "BoringDAO",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "500000000000000000",
              "balance": 0.5,
              "balanceUSD": 11.915,
              "price": 23.83,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ALFw3",
              "address": "0xdf7a6a1214b3cbd3f9812434437f61a0d4cbbe1f",
              "source": "CVQKM",
              "label": "ALFweb3Project",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "800000000000000000000",
              "balance": 800,
              "balanceUSD": 8.519128,
              "price": 0.01064891,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MATH",
              "address": "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
              "source": "CVQKM",
              "label": "MATH Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "60000000000000000000",
              "balance": 60,
              "balanceUSD": 6.20934,
              "price": 0.103489,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "$BASED",
              "address": "0x68a118ef45063051eac49c7e647ce5ace48a68a5",
              "source": "CVQKM",
              "label": "$BASED",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000",
              "balance": 10,
              "balanceUSD": 2.62704,
              "price": 0.262704,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BAL",
              "address": "0xba100000625a3754423978a60c9317c58a424e3d",
              "source": "CVQKM",
              "label": "BAL",
              "network": "ethereum",
              "protocol": "Balancer",
              "balanceRaw": "364812732062614180",
              "balance": 0.3648127320626142,
              "balanceUSD": 1.9444518618937339,
              "price": 5.33,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xba100000625a3754423978a60c9317c58a424e3d.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SURF",
              "address": "0xea319e87cf06203dae107dd8e5672175e3ee976c",
              "source": "CVQKM",
              "label": "SURF.Finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "49500000000000000000",
              "balance": 49.5,
              "balanceUSD": 1.832789475,
              "price": 0.03702605,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ALEX",
              "address": "0x8ba6dcc667d3ff64c1a2123ce72ff5f0199e5315",
              "source": "CVQKM",
              "label": "AlexMasmej",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1954177",
              "balance": 195.4177,
              "balanceUSD": 1.639208613671,
              "price": 0.00838823,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DNT",
              "address": "0x0abdace70d3790235af448c88547603b945604ea",
              "source": "CVQKM",
              "label": "district0x Network Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "69000000000000000000",
              "balance": 69,
              "balanceUSD": 1.22081286,
              "price": 0.01769294,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x0abdace70d3790235af448c88547603b945604ea.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RARI",
              "address": "0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
              "source": "CVQKM",
              "label": "Rarible",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "555000000000000000",
              "balance": 0.555,
              "balanceUSD": 0.8769000000000001,
              "price": 1.58,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TRIPS",
              "address": "0x1350fbe8ce27762ec19134bf8fc405a427fe9bf1",
              "source": "CVQKM",
              "label": "Trips",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "99999999999000000000",
              "balance": 99.999999999,
              "balanceUSD": 0.48541199999514584,
              "price": 0.00485412,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "JET",
              "address": "0x8727c112c712c4a03371ac87a74dd6ab104af768",
              "source": "CVQKM",
              "label": "Jetcoin",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000",
              "balance": 50,
              "balanceUSD": 0.483684,
              "price": 0.00967368,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "S4F",
              "address": "0xaec7d1069e3a914a3eb50f0bfb1796751f2ce48a",
              "source": "CVQKM",
              "label": "S4FE",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1337000000000000000000",
              "balance": 1337,
              "balanceUSD": 0.44567558,
              "price": 0.00033334,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ZUM",
              "address": "0xe0b9bcd54bf8a730ea5d3f1ffce0885e911a502c",
              "source": "CVQKM",
              "label": "Zum Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "7737750000000",
              "balance": 77377.5,
              "balanceUSD": 0.444920625,
              "price": 0.00000575,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LIEN",
              "address": "0xab37e1358b639fd877f015027bb62d3ddaa7557e",
              "source": "CVQKM",
              "label": "lien",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000",
              "balance": 1,
              "balanceUSD": 0.437723,
              "price": 0.437723,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SNOW",
              "address": "0xfe9a29ab92522d14fc65880d817214261d8479ae",
              "source": "CVQKM",
              "label": "SnowSwap",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0.319042,
              "price": 0.319042,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PKG",
              "address": "0x02f2d4a04e6e01ace88bd2cd632875543b2ef577",
              "source": "CVQKM",
              "label": "PKG Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0.12119999999999999,
              "price": 0.00001212,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "wLITI",
              "address": "0x0b63128c40737b13647552e0c926bcfeccc35f93",
              "source": "CVQKM",
              "label": "wLitiCapital",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000",
              "balance": 50,
              "balanceUSD": 0.11896949999999999,
              "price": 0.00237939,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TOS",
              "address": "0x34748fedad669c788aabe4fb325a4587941f7b48",
              "source": "CVQKM",
              "label": "TOSA INU",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000000000",
              "balance": 1000000000,
              "balanceUSD": 0.097108,
              "price": 9.7108e-11,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FERA",
              "address": "0x539f3615c1dbafa0d008d87504667458acbd16fa",
              "source": "CVQKM",
              "label": "FERA",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0.046,
              "price": 0.0000046,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "IOTX",
              "address": "0x6fb3e0a217407efff7ca062d46c26e5d60a14d69",
              "source": "CVQKM",
              "label": "IoTeX Network",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0.02097123,
              "price": 0.02097123,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FLUX",
              "address": "0x469eda64aed3a3ad6f868c44564291aa415cb1d9",
              "source": "CVQKM",
              "label": "FLUX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "231426333049651022",
              "balance": 0.23142633304965102,
              "balanceUSD": 0.011633107483406808,
              "price": 0.050267,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WAIF",
              "address": "0xb2279b6769cfba691416f00609b16244c0cf4b20",
              "source": "CVQKM",
              "label": "Waifu",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "69000000000000000000",
              "balance": 69,
              "balanceUSD": 0.0103638,
              "price": 0.0001502,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BALI",
              "address": "0x013a06558f07d9e6f9a00c95a33f3a0e0255176b",
              "source": "CVQKM",
              "label": "BALI COIN",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ELON2.0",
              "address": "0x028a449241bb0ea08a0b1c5a6c6cf9f0fec92198",
              "source": "CVQKM",
              "label": "DogeElon Mars2.0",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "439598999999999",
              "balance": 439598.999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RIN",
              "address": "0x03bbeab8d41fbac2477b417b5df7cbc7d939c44a",
              "source": "CVQKM",
              "label": "Kirin.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "168888888880000000000",
              "balance": 168.88888888,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DOG",
              "address": "0x050ca92d34f7eab992b2c6ef5293ed684f8dbe8f",
              "source": "CVQKM",
              "label": "DogDays",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "199999999000000000000000000",
              "balance": 199999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HATE",
              "address": "0x054bd236b42385c938357112f419dc5943687886",
              "source": "CVQKM",
              "label": "HeavensGate",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "9750000000000000",
              "balance": 0.00975,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETH2POS",
              "address": "0x0698dda3c390ff92722f9eed766d8b1727621df9",
              "source": "CVQKM",
              "label": "Ethereum2Proof-of-stake",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "4922808411670482723865",
              "balance": 4922.808411670483,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VOTER",
              "address": "0x0a5630136d04bd9dc421a8c5a8e28da1a117707f",
              "source": "CVQKM",
              "label": "voterblock.io",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UNI-V2",
              "address": "0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852",
              "source": "CVQKM",
              "label": "Uniswap V2",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1544641648282",
              "balance": 0.000001544641648282,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETH 2.0",
              "address": "0x0dd7cf1d102118fcb498fc5b9a5f23c043407748",
              "source": "CVQKM",
              "label": "Ethereum 2.0",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "35096351552378900036417012812",
              "balance": 35096351552.3789,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BEN",
              "address": "0x108c05cac356d93b351375434101cfd3e14f7e44",
              "source": "CVQKM",
              "label": "Token of Szczepan Bentyn",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MAFIA",
              "address": "0x10e790a1c02fd30867722705129e9b2178af950f",
              "source": "CVQKM",
              "label": "MainstreamMediaMafia",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2451000000000000000000",
              "balance": 2451.0000000000005,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "THREE",
              "address": "0x14fd3063dfd3ce0a2c4c5ee43ab49988a989202c",
              "source": "CVQKM",
              "label": "3 Comma Club",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DLY",
              "address": "0x16bdc51e2873ffa5398c8a736c6c5de7bee929a5",
              "source": "CVQKM",
              "label": "Dalycoin",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000000",
              "balance": 99999.99999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETHW",
              "address": "0x170cb6652c4b28fa601903439f1aee00a3f59ac8",
              "source": "CVQKM",
              "label": "PoW ETH",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "69712882000000697834468512",
              "balance": 69712882.0000007,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TVO",
              "address": "0x1c0c9ce4a4a7d4f70c4eb6dbf7973ab547689977",
              "source": "CVQKM",
              "label": "TVCOM.IO  - Patented SweepChain Innovation for Fortune 500 Companies",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "999900000",
              "balance": 9999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 5,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LINK",
              "address": "0x1e1c1bd076c3578c040f33e83edce96861e33ff7",
              "source": "CVQKM",
              "label": "ChainLink Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "107475321848200000000000",
              "balance": 107475.3218482,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CPR",
              "address": "0x20ae0ca9d42e6ffeb1188f341a7d63450452def6",
              "source": "CVQKM",
              "label": "Cipher",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1008000000000000000000",
              "balance": 1008,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PJX",
              "address": "0x21edc2552bbcfe51f733fab0cff02a1c80fa69ba",
              "source": "CVQKM",
              "label": "ProjectX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10",
              "balance": 1e-17,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HARVESTER",
              "address": "0x25b0f39e71a288a6c1b0f69c6bf1cc3c20a896b6",
              "source": "CVQKM",
              "label": "harvester.network",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "364000000000000000000000",
              "balance": 364000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ERUPT",
              "address": "0x269645dfb0debda18db0d55846510cff5bd2d77a",
              "source": "CVQKM",
              "label": "ERUPT",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "195434490779696091946808",
              "balance": 195434.4907796961,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TADA",
              "address": "0x2a746fb4d7338e4677c102f0ce46dae3971de1cc",
              "source": "CVQKM",
              "label": "Tada!",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "213000000000000000",
              "balance": 0.213,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HERC",
              "address": "0x2e91e3e54c5788e9fdd6a181497fdcea1de1bcc1",
              "source": "CVQKM",
              "label": "Hercules",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1337000000000000000000",
              "balance": 1337,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BC",
              "address": "0x2ecb13a8c458c379c4d9a7259e202de03c8f3d19",
              "source": "CVQKM",
              "label": "Block-Chain.com Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "66666666666666666660",
              "balance": 66.66666666666666,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XIO",
              "address": "0x3205df88cf95b5a702f2b6a1cc10e2075f54387c",
              "source": "CVQKM",
              "label": "BOMBX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "272500000000",
              "balance": 2725,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YDG",
              "address": "0x35a2c900578a2987b08ff3f0ff58fd6f137f2580",
              "source": "CVQKM",
              "label": "yDoge",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100",
              "balance": 1e-16,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HOLY",
              "address": "0x39eae99e685906ff1c11a962a743440d0a1a6e09",
              "source": "CVQKM",
              "label": "Holyheld",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "5000000000000000000",
              "balance": 5,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WHYNOTKILL",
              "address": "0x3d2c38116e87a21ca6454afeb455f01929885105",
              "source": "CVQKM",
              "label": "/www.twitch.tv/whynotkme",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "3000000000000000000",
              "balance": 3,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GPYX",
              "address": "0x3e3cda3218212503883c79f6d2feef29e6bbb87d",
              "source": "CVQKM",
              "label": "GpyxToken",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "JEFF",
              "address": "0x42a97bdec3ee2dbdc49f980e9a45c773035d87a2",
              "source": "CVQKM",
              "label": "JeffToken",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000",
              "balance": 2,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BSJ",
              "address": "0x43901e05f08f48546fff8d6f8df108f60570498b",
              "source": "CVQKM",
              "label": "BASENJI",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000",
              "balance": 50,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SCAM",
              "address": "0x49488350b4b2ed2fd164dd0d50b00e7e3f531651",
              "source": "CVQKM",
              "label": "ScamToken",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10101010101010101",
              "balance": 0.0101010101010101,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "love",
              "address": "0x4a057f3f6a393123ec6b72762bbcaea398b528ca",
              "source": "CVQKM",
              "label": "MayKinFund",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UNIDEFI",
              "address": "0x4ba988575fcfe79b81a79cdffb2ef9ece268115e",
              "source": "CVQKM",
              "label": "UniDeFi.Finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1946667",
              "balance": 1.946667,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "$CDC",
              "address": "0x53b8e4461ab33dd166a2a953881e3b472115f599",
              "source": "CVQKM",
              "label": "Creepy Dough Currency",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "56952351796774",
              "balance": 56952.351796774,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SHILL",
              "address": "0x54c818a55a795493de47620fa08efae74fc48974",
              "source": "CVQKM",
              "label": "ShillDAO",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CBDCY",
              "address": "0x552cd48d0119cd4c83fccb46a32c83867afbbf18",
              "source": "CVQKM",
              "label": "CBDCYuan",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000000",
              "balance": 49999.99999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XYR",
              "address": "0x57e82898ded95b48881aff85590a30a1db2305f1",
              "source": "CVQKM",
              "label": "AllyFocus",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CDK",
              "address": "0x5a86de868f7b8bed45dcc64854b3713b294802a6",
              "source": "CVQKM",
              "label": "CertDocKits Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YFVW",
              "address": "0x5e7ecd90703337f3046f0e040071eb3277d8d1c9",
              "source": "CVQKM",
              "label": "yfvw.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DES",
              "address": "0x5e9c099d9945dbe107a8ff67eea7eb0df7f3ac9f",
              "source": "CVQKM",
              "label": "Dennis Schlegel",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10",
              "balance": 10,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ELITE",
              "address": "0x5fe0d5d1ae0dc12ae6a304603868ecb840bfbd4d",
              "source": "CVQKM",
              "label": "The Elite Hodlers",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FUCK",
              "address": "0x65be44c747988fbf606207698c944df4442efe19",
              "source": "CVQKM",
              "label": "Finally Usable Crypto Karma",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "320000",
              "balance": 32,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BUIT",
              "address": "0x661d0e19c70106e005e57ca28eb5cd034f9a82bb",
              "source": "CVQKM",
              "label": "buildit",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TLN",
              "address": "0x679131f591b4f369acb8cd8c51e68596806c3916",
              "source": "CVQKM",
              "label": "Trustlines Network Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "13131313131313131300",
              "balance": 13.131313131313131,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x679131f591b4f369acb8cd8c51e68596806c3916.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ELET",
              "address": "0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43",
              "source": "CVQKM",
              "label": "Elementeum",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "133700000000000000",
              "balance": 0.1337,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XBOX",
              "address": "0x6e4bf3b1903e97e8caee27a6461f306a5edc656e",
              "source": "CVQKM",
              "label": "XBOX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "7777777000000000000",
              "balance": 7.777777,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "777",
              "address": "0x77765208cad3040bbea18243496f66ffef48a2c2",
              "source": "CVQKM",
              "label": "777",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "777777700000",
              "balance": 7777777,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 5,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TOB",
              "address": "0x7777770f8a6632ff043c8833310e245eba9209e6",
              "source": "CVQKM",
              "label": "Tokens of Babel, Price is God",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "848165513535263127",
              "balance": 0.8481655135352631,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CReth",
              "address": "0x7a75fc866c09927aa3e4619fd529173d81283102",
              "source": "CVQKM",
              "label": "CanETH Rewards",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "5242880000000000000000000",
              "balance": 5242880,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FLA",
              "address": "0x7be5901f679bde8202a123c84c19bbce2cf3449b",
              "source": "CVQKM",
              "label": "Fiola",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "666666000000000000000000",
              "balance": 666666,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SMDX",
              "address": "0x7e8539d1e5cb91d63e46b8e188403b3f262a949b",
              "source": "CVQKM",
              "label": "SOMIDAX",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CRX",
              "address": "0x80acb802869f7b825cdbdacb504cf95b595bb930",
              "source": "CVQKM",
              "label": "Core",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BFIE",
              "address": "0x81859801b01764d4f0fa5e64729f5a6c3b91435b",
              "source": "CVQKM",
              "label": "bearn.fi",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "8888888899999999",
              "balance": 0.0088888889,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x81859801b01764d4f0fa5e64729f5a6c3b91435b.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KIN",
              "address": "0x818fc6c2ec5986bc6e2cbf00939d90556ab12ce5",
              "source": "CVQKM",
              "label": "Kin",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NUTS",
              "address": "0x84294fc9710e1252d407d3d80a84bc39001bd4a8",
              "source": "CVQKM",
              "label": "Squirrel Finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "13370000000000000000",
              "balance": 13.37,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SGT",
              "address": "0x84810bcf08744d5862b8181f12d17bfd57d3b078",
              "source": "CVQKM",
              "label": "SharedStake Governance Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "420690000000000000",
              "balance": 0.42069,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DFIN",
              "address": "0x84f42bc7cab3932bdf1c77bb08528bff20a44180",
              "source": "CVQKM",
              "label": "DEFINANCE",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YNK",
              "address": "0x87c00817abe35ed4c093e59043fae488238d2f74",
              "source": "CVQKM",
              "label": "YOINK",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000000",
              "balance": 2000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DEGO",
              "address": "0x88ef27e69108b2633f8e1c184cc37940a075cc02",
              "source": "CVQKM",
              "label": "dego.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "30380000000000000000",
              "balance": 30.38,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CCORE",
              "address": "0x891639c1c2f647b0239067601b0ef917e0379e6a",
              "source": "CVQKM",
              "label": "CumSwapCORE",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "5000000000000000000",
              "balance": 5,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ARI",
              "address": "0x8a8b5318d3a59fa6d1d0a83a1b0506f2796b5670",
              "source": "CVQKM",
              "label": "Denarii",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "147070000000",
              "balance": 1470.7,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FEW",
              "address": "0x8d588b66b9c605bd1f6e9b75cb9365aad5b97140",
              "source": "CVQKM",
              "label": "FEW",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "42069000000000000000",
              "balance": 42.069,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PXP",
              "address": "0x8f179114235842978d8917e08721541072c46584",
              "source": "CVQKM",
              "label": "PointPay Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "7225000",
              "balance": 7225,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 3,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BT12",
              "address": "0x9121f68b9c403794479017b808effbb9296ac6bc",
              "source": "CVQKM",
              "label": "btc12",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "3000000000000000000",
              "balance": 3,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Raze",
              "address": "0x94bccfeefa17a1b9dabc93defdebd4951d864362",
              "source": "CVQKM",
              "label": "RazeNetwork",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LOOT032",
              "address": "0x9648915f9a4b6778c3d3716bc084f0a44e4cba48",
              "source": "CVQKM",
              "label": "Gift Bag",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000",
              "balance": 10,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "李孔依玄",
              "address": "0x978d70b93f12f645d417ff3a15d9911582f32cfd",
              "source": "CVQKM",
              "label": "李孔依玄",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "102400",
              "balance": 1024,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "K33PR",
              "address": "0x97f77f2f307a53e6120d38eb44b9d2f75405a707",
              "source": "CVQKM",
              "label": "k33pr.com",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SEXY",
              "address": "0x98f5e9b7f0e33956c0443e81bf7deb8b5b1ed545",
              "source": "CVQKM",
              "label": "Sexy Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "32000000000000000000",
              "balance": 32,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PGO",
              "address": "0x9ad194c9abec8f99325fe8252d5adff1c398ace5",
              "source": "CVQKM",
              "label": "PgoChain",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "106000000000000000000",
              "balance": 106,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YFD",
              "address": "0x9c51699b4cd43ba4f6bc81d70b21291b63e6d130",
              "source": "CVQKM",
              "label": "YFDiamond.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "3800000000000000000",
              "balance": 3.8,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WAGE",
              "address": "0x9d6df5de56ed924b5e54ebbffd368d89fd134520",
              "source": "CVQKM",
              "label": "Wage",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "7059166890000000",
              "balance": 0.00705916689,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UMB",
              "address": "0x9dacdbaacff26b233bc6f969f57c61aa0742d4df",
              "source": "CVQKM",
              "label": "Umbrella",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000",
              "balance": 10,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SHIBL",
              "address": "0xa1306635b47c5f6745f1147697d771049bec1e0b",
              "source": "CVQKM",
              "label": "LEGEND OF SHIBA INU",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "250000000000000000000000000000",
              "balance": 250000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YFE",
              "address": "0xa156db88c817a58a856b8558eedd7424a98521b0",
              "source": "CVQKM",
              "label": "yfelite.finance",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "8000000000000000000000",
              "balance": 8000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YARRR",
              "address": "0xa1ce38ac41480abd9f84dcf1160044e9e991931a",
              "source": "CVQKM",
              "label": "Pirate Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000",
              "balance": 2,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UMB",
              "address": "0xa22e0d6ab33d80172cd15c5e809ee03f0422893a",
              "source": "CVQKM",
              "label": "Umbrella",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LEX",
              "address": "0xa5c5c8af327248c4c2dce810a3d3cffb8c4f66ab",
              "source": "CVQKM",
              "label": "LexDAO Engineering",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000",
              "balance": 10,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ALEO",
              "address": "0xa8162a07efa81602c3803772d18d1789a44fd87a",
              "source": "CVQKM",
              "label": "ALEO",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "320000000",
              "balance": 320000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 3,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BSC",
              "address": "0xac1ec3143b89d5b263d9194db216ea068e0f3dc9",
              "source": "CVQKM",
              "label": "Bitcoin Startup Capital",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BEB",
              "address": "0xb0dc9ad91625bcdd00b1b08e7560e8fa9b843f1c",
              "source": "CVQKM",
              "label": "betbeb.com",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "19000000000000000000000",
              "balance": 19000.000000000004,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MADCAP",
              "address": "0xb28f9c9b71b0c3885ae441a3c0f51027b760a991",
              "source": "CVQKM",
              "label": "MADCAP",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "6130000",
              "balance": 613,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RTK",
              "address": "0xb30ef6741529736f2cd9b8cb44484a8429209aee",
              "source": "CVQKM",
              "label": "Ruletka",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RPL",
              "address": "0xb4efd85c19999d84251304bda99e90b92300bd93",
              "source": "CVQKM",
              "label": "Rocket Pool",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "690000000000000000",
              "balance": 0.69,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SKTG",
              "address": "0xb6d11c0bd7df8a3728ca972ebd9136ac6b0d92c0",
              "source": "CVQKM",
              "label": "Speedkingtoken Gold",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000",
              "balance": 0.1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PLT",
              "address": "0xb827617570518a8e589089f9e43a7036390a4929",
              "source": "CVQKM",
              "label": "Plutus Defi",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "500000000000000000000",
              "balance": 500,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EVILCULT",
              "address": "0xbb3c07f465b00581b9281fbce621e711b636e9a2",
              "source": "CVQKM",
              "label": "twitchers&youtubersarekillingm",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "78000000000000000000",
              "balance": 78,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XSTAR",
              "address": "0xc0e47007e084eef3ee58eb33d777b3b4ca98622f",
              "source": "CVQKM",
              "label": "StarCurve",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1337000000000000000000",
              "balance": 1337,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YFIG",
              "address": "0xc36f33a94d371ef4dfbeae8d50d3520ac02a41de",
              "source": "CVQKM",
              "label": "yfi.group",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000",
              "balance": 0.01,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HOKK",
              "address": "0xc40af1e4fecfa05ce6bab79dcd8b373d2e436c4e",
              "source": "CVQKM",
              "label": "Hokkaidu Inu",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "66824988436333315981",
              "balance": 66824988436.33331,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GOOGLE",
              "address": "0xc668951b19fe92bb6dbe77a80a67fd586442d227",
              "source": "CVQKM",
              "label": "GoogleRuns4&8chan&qannon",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "666000000000000000000",
              "balance": 666,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "QCOIN",
              "address": "0xc6be7c1be5920e40f7ebd5fbb140d0c408389636",
              "source": "CVQKM",
              "label": "QCoins",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1000000000",
              "balance": 10000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CUTE",
              "address": "0xc6f39a704b4f356369869bf5b01e74cdf06cebb2",
              "source": "CVQKM",
              "label": "Completely Useless Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "",
              "address": "0xc7fd9ae2cf8542d71186877e21107e1f3a0b55ef",
              "source": "CVQKM",
              "label": "cryptocurrency",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000",
              "balance": 0.01,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GAMEOFTHRO",
              "address": "0xc93c29840e60c70eeedee69e21486a31d7744e5f",
              "source": "CVQKM",
              "label": "v=dzBS5Dr-qrY",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "18000000000000000000",
              "balance": 18,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KP3Rd",
              "address": "0xcc6c7833813805446ce896634569ed9fdb7414f7",
              "source": "CVQKM",
              "label": "KEEP3Rd",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "1615733334",
              "balance": 1615.733334,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WHACKD",
              "address": "0xcf8335727b776d190f9d15a54e6b9b9348439eee",
              "source": "CVQKM",
              "label": "Whackd",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "8322701862510000000000",
              "balance": 8322.70186251,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETV",
              "address": "0xd069c0fbbccf352fd3e10a571f3eb4717f4efa9e",
              "source": "CVQKM",
              "label": "Global entropy",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "12000000000000000000",
              "balance": 12,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MINA",
              "address": "0xd5745a490f14cda24bed11156d91eb7a77c8cb1b",
              "source": "CVQKM",
              "label": "Mina Protocol Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "52700000000000000000000",
              "balance": 52700,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BBS",
              "address": "0xd6fc34d789452fae6cf73e5014b2919140fe6642",
              "source": "CVQKM",
              "label": "BIG BIG SIZE",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000",
              "balance": 10000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PERS",
              "address": "0xd9fda7547c3111bc6ced459748d25ace1a1a2848",
              "source": "CVQKM",
              "label": "Persian",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2039942661888326022964",
              "balance": 2039.942661888326,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GOD",
              "address": "0xda10a87dc078fc04f2871e317726447a0ea91cf4",
              "source": "CVQKM",
              "label": "Token of God",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "45800000",
              "balance": 0.458,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KOTI",
              "address": "0xe28a3ac5ccdd3f86ecac7252d4dd5cd44e859fa5",
              "source": "CVQKM",
              "label": "Koti Inu",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000000",
              "balance": 10000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ANTISEAL",
              "address": "0xe95e4440493e5b96e79d63e8dc43ab676dd44e4c",
              "source": "CVQKM",
              "label": "AntiSeal",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "6",
              "balance": 6,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DUDE",
              "address": "0xe98b5f11897d42afd17185dd301bcb2d46389bf1",
              "source": "CVQKM",
              "label": "Dude",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "4200000",
              "balance": 420,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EYB",
              "address": "0xea21501ff06b82737caadad6f0b11def4e96ebff",
              "source": "CVQKM",
              "label": "CROCODILEKING.COM",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "500000000000000000000000000",
              "balance": 500000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PARETO",
              "address": "0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc",
              "source": "CVQKM",
              "label": "Pareto Network Token",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000000",
              "balance": 99999.99999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UNIF",
              "address": "0xec02cec4edd54196d2767b61f43b29a49b056fe6",
              "source": "CVQKM",
              "label": "Unifier",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "500000000000000",
              "balance": 50000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 7,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MINA",
              "address": "0xed746b0a22f692387917cfe13f32b49e5aef6b78",
              "source": "CVQKM",
              "label": "Mina Protocol",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000000000",
              "balance": 2000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EBOS",
              "address": "0xf2ad92992fdae601988adf019cf6f3b93d13d910",
              "source": "CVQKM",
              "label": "EBOSS",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "8888888800000000000",
              "balance": 8.8888888,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YFIG",
              "address": "0xf3a231bf11a0ef1a3074cf5a1f5620bc85d975fb",
              "source": "CVQKM",
              "label": "Yearn Finance Global",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "5000000000000000000",
              "balance": 5,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WSKY",
              "address": "0xf40a5b54e0555ab3f0fb010bf7ad00d8a4d6cd54",
              "source": "CVQKM",
              "label": "WSKY",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000000",
              "balance": 99999.99999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DANNY",
              "address": "0xf97ce80cd21e18006dd9c3edc07c983c158f9371",
              "source": "CVQKM",
              "label": "DANNY",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "6900",
              "balance": 0.69,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DUCAT",
              "address": "0xf9f388532f2c6bd7f1e68cc102ae76448406d805",
              "source": "CVQKM",
              "label": "DUCAT",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "88091000000000000000",
              "balance": 88.091,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DoTx",
              "address": "0xfab5a05c933f1a2463e334e011992e897d56ef0a",
              "source": "CVQKM",
              "label": "DeFi Of Thrones",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "2500000000000000000000",
              "balance": 2500,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SHLIAPA",
              "address": "0xfda3b27ce3ab814cea7715bd960d8b8c04eb351e",
              "source": "CVQKM",
              "label": "SHLIAPA",
              "network": "ethereum",
              "protocol": "tokens",
              "balanceRaw": "11111111111111000000",
              "balance": 11.111111111111,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            }
          ]
        },
        {
          "name": "zksync",
          "data": [
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETH",
              "address": "zksync:ETH",
              "source": "ZKSHG",
              "label": "ETH",
              "network": "zksync",
              "balanceRaw": "32000000000000",
              "protocol": "zksync",
              "balance": 0.000032,
              "balanceUSD": 0.038758739072,
              "price": 1211.210596,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:45.192Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "USDC",
              "address": "zksync:USDC",
              "source": "ZKSHG",
              "label": "USDC",
              "network": "zksync",
              "balanceRaw": "120000",
              "protocol": "zksync",
              "balance": 0.12,
              "balanceUSD": 0.12005400000000001,
              "price": 1.00045,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:08:45.192Z",
              "status": true
            }
          ]
        },
        {
          "name": "bsc",
          "data": [
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BNB",
              "address": "bnb",
              "source": "CVQKM",
              "label": "Binance Coin (BNB)",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1045134598527558896",
              "balance": 1.0451345985275589,
              "balanceUSD": 256.9494869031419,
              "price": 245.85301,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/bnb.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ETH",
              "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
              "source": "CVQKM",
              "label": "Ethereum Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "58120620000000000",
              "balance": 0.05812062,
              "balanceUSD": 70.47812741934601,
              "price": 1212.6183,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x2170ed0880ac9a755fd29b2688956bd959f933f8.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EMC1",
              "address": "0xf44adbe0212b1b816f6c5d209bce67e363f40ac8",
              "source": "CVQKM",
              "label": "EMCIS COIN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "86000000",
              "balance": 86,
              "balanceUSD": 0.2602215606,
              "price": 0.0030258321,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CP",
              "address": "0xe9c133d3d94263c7ac7b848168138d7cf2ceba14",
              "source": "CVQKM",
              "label": "CrystalPowder",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "17000000000000000000",
              "balance": 17,
              "balanceUSD": 0.06790532699999999,
              "price": 0.003994431,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Ape",
              "address": "0x0059125c7349d0957d199e4c8038e78f5dde4ace",
              "source": "CVQKM",
              "label": "Ape",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "lonelyMoon",
              "address": "0x00fc914bbc844f855ffd671c25b6609fa2b474ba",
              "source": "CVQKM",
              "label": "lonelyMoon",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "5100000000000000",
              "balance": 5100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AMPP",
              "address": "0x016439cadf4d73916e33a62be9f49894bf1cea62",
              "source": "CVQKM",
              "label": "AMPP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AHI",
              "address": "0x01e2c86c874b3c9e4268aec23fc4b433aa1beca1",
              "source": "CVQKM",
              "label": "AHI COIN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "200000000000000000000",
              "balance": 200,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TDAO",
              "address": "0x025f00eded152e1d081703c80ee44c737f22b12b",
              "source": "CVQKM",
              "label": "TDAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Wing",
              "address": "0x027b0f9ccba2c69fea5f1d0102778411e15bbd6b",
              "source": "CVQKM",
              "label": "Wing",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TVL",
              "address": "0x049c722e4e9610456dc39fdef3596cc847f7f9ad",
              "source": "CVQKM",
              "label": "TVL",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SSO",
              "address": "0x06055b212fc9c7a7e8a596bd3d8948127c4aa980",
              "source": "CVQKM",
              "label": "SSO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Hermes",
              "address": "0x081604a2d99afe95f1f05da75bafd1d51ef86004",
              "source": "CVQKM",
              "label": "Hermes",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "300000000000",
              "balance": 300,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KVS",
              "address": "0x08e8f0d1b284df3ec9b611bd032ecadcafa39ecb",
              "source": "CVQKM",
              "label": "Kangal vs Shiba",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Game ",
              "address": "0x09efb9c7b8fb727733fac0b335b9bf205dfaec11",
              "source": "CVQKM",
              "label": "Game ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpaceKing",
              "address": "0x09f03d7d013ce2c5eb8e1eb408f159e35daee4ee",
              "source": "CVQKM",
              "label": "SpaceKing",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MXX",
              "address": "0x0ac634ac8160259ba1c6bb5b6232fa3ab64d4db0",
              "source": "CVQKM",
              "label": "MXX",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ARNX",
              "address": "0x0c37bcf456bc661c14d596683325623076d7e283",
              "source": "CVQKM",
              "label": "Aeron",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "101000000000000000000",
              "balance": 101,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SF",
              "address": "0x0ccec619169982950290cbb8b353cbce892a3def",
              "source": "CVQKM",
              "label": "Salted Flsh",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "75358800000000",
              "balance": 753588,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PCI",
              "address": "0x0df5bc8e5530182caf47705f5a7b90434b58d73c",
              "source": "CVQKM",
              "label": "Paycoin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VERA",
              "address": "0x0df62d2cd80591798721ddc93001afe868c367ff",
              "source": "CVQKM",
              "label": "TheVera.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "800000000000000000000000",
              "balance": 799999.9999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x0df62d2cd80591798721ddc93001afe868c367ff.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Cake-LP",
              "address": "0x0e9840b2cba3881339f097eef8060a4fd48bc50e",
              "source": "CVQKM",
              "label": "Pancake LPs",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 0.0001,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DODS",
              "address": "0x0eda790ff05cffb4ec6c24d5ba5630894485ff62",
              "source": "CVQKM",
              "label": "DODS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Amar",
              "address": "0x0fa5156895569ebcf28f72f4fa30ca80db70a511",
              "source": "CVQKM",
              "label": "Amar",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Sing",
              "address": "0x0fbd561cdb64993d13ecbade447d37bbc80323f4",
              "source": "CVQKM",
              "label": "Sing",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VOV",
              "address": "0x0fcd09d052cc9daf52e419ce3abbeb6a02fa7a76",
              "source": "CVQKM",
              "label": "VOV",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Zepe.io",
              "address": "0x119e2ad8f0c85c6f61afdf0df69693028cdc10be",
              "source": "CVQKM",
              "label": "Zepe.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "750000000000000000000000",
              "balance": 750000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Single",
              "address": "0x15980d183581e847bbaa754e37331b67d6dff43a",
              "source": "CVQKM",
              "label": "Single Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Move",
              "address": "0x16e8375850c5ff757a509f6cf38a58135519610d",
              "source": "CVQKM",
              "label": "Move",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "98000000000000",
              "balance": 98000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VELO",
              "address": "0x17d1285bc68d9085f8e4b86fc565e452b29dc48f",
              "source": "CVQKM",
              "label": "VeloChain.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "150000000000000000000000",
              "balance": 150000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Saga",
              "address": "0x17e9ec129b375c75681430f5fa84e1f711b0ea50",
              "source": "CVQKM",
              "label": "Saga",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HER",
              "address": "0x180af5057cdd3df828f94fa4096f742ea10aacae",
              "source": "CVQKM",
              "label": "Hermes",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "9980000000000000000000000",
              "balance": 998000000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 10,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Pool",
              "address": "0x1a4d7c70ebdb4dcb770a1d0662215ec1c16b404a",
              "source": "CVQKM",
              "label": "Pool",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Ape",
              "address": "0x1b4e4dccbb836f435d3a5392274b9315d48f4741",
              "source": "CVQKM",
              "label": "ApeCoin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "wow",
              "address": "0x1c829866d0803da3099b715c65340054c4fae130",
              "source": "CVQKM",
              "label": "wow",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Fee",
              "address": "0x1cc413a08265b15c1bca2253e70ed72afc3beab7",
              "source": "CVQKM",
              "label": "Fee",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Luxy",
              "address": "0x1d38c0b9676fe156e447080de3de177d17305759",
              "source": "CVQKM",
              "label": "Luxy",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpaceGm",
              "address": "0x1db3ac69cd2f56269a70ae6bbea20a8dea3f16cf",
              "source": "CVQKM",
              "label": "SpaceGm",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NBS",
              "address": "0x1e09c9f58d427ba3dc038e034fa5147c2190ccef",
              "source": "CVQKM",
              "label": "NBS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpacePig",
              "address": "0x208c05babbeca15b1fb4e002d2c3b59e73a06706",
              "source": "CVQKM",
              "label": "SpacePig",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GMO",
              "address": "0x2226b4234174255e6192285b626f6db3e806c2ff",
              "source": "CVQKM",
              "label": "GMO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RACAS",
              "address": "0x239c3f1783588265fe5ed6efb129f45409b3e074",
              "source": "CVQKM",
              "label": "RACAS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MeMe",
              "address": "0x23d04c0f3ec6b4afd1d985bccdafa314fcac0ed3",
              "source": "CVQKM",
              "label": "MeMe",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "JAMOON",
              "address": "0x2ab02106165171f1aa54537f886e71d3378905d7",
              "source": "CVQKM",
              "label": "JaguarMOON",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "5600000007318203275992155",
              "balance": 5600000007318204,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MoonPi",
              "address": "0x303a3214c6151e28932abdd91ba84d2b450f4590",
              "source": "CVQKM",
              "label": "MoonPi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Mainnet",
              "address": "0x304a61534342b7847c464a1f7dfde8ae13fa2084",
              "source": "CVQKM",
              "label": "Mainnet",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CWD",
              "address": "0x309632da0bdee76f8794cef26f5271959a4fa58b",
              "source": "CVQKM",
              "label": "CWD",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KMT",
              "address": "0x30fccda1152a948d0b7f249cd9ff7af2186e6491",
              "source": "CVQKM",
              "label": "KMT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BabyTiger",
              "address": "0x31544d5d1fa5d9cbed61885620038860eaf809f2",
              "source": "CVQKM",
              "label": "BabyTiger DAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "8996400000000000000",
              "balance": 8.9964,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RDAO",
              "address": "0x315f56906c5dc531efa2f31dc545c4e16e569c15",
              "source": "CVQKM",
              "label": "RDAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SDK",
              "address": "0x32a2705d10d3b256efe9d3b9a43a9b61f0c32f9f",
              "source": "CVQKM",
              "label": "SDK",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "QQQ",
              "address": "0x3335b07916ddb08afb6715991d1a244a70557c2f",
              "source": "CVQKM",
              "label": "QQQ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Blocktopia",
              "address": "0x3468427cd06ab67049f86566a5033137b1d8fb85",
              "source": "CVQKM",
              "label": "Blocktopia",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NPT",
              "address": "0x34708da1ff8cb9fa8490818fcb9060b7cd8efd7a",
              "source": "CVQKM",
              "label": "NPT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "888X",
              "address": "0x348ef03ef1195ead73be3a0cc8619d56cbf464c1",
              "source": "CVQKM",
              "label": "888X",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "95000000000000",
              "balance": 95000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Crazy",
              "address": "0x35351c9cc204d28fca730ad63e883802749bedf8",
              "source": "CVQKM",
              "label": "Crazy",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 0.0001,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TKing",
              "address": "0x376ecc7e124c0c2ad25b070e5fe0cbbcfd0d8056",
              "source": "CVQKM",
              "label": "TKing",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LMR",
              "address": "0x37945464167765f8bc7046d83b7f25ab85b47026",
              "source": "CVQKM",
              "label": "LMR",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PDAO",
              "address": "0x37b3656d695317dff2e2f007dd0d01cf1688b509",
              "source": "CVQKM",
              "label": "Peace DAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Timeswap",
              "address": "0x39b0f952dba58d5c28f8fcda4561ed0b6cf1be12",
              "source": "CVQKM",
              "label": "Timeswap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "IBULL",
              "address": "0x3a0889865caac6bcb276f52a77318b043468a51e",
              "source": "CVQKM",
              "label": "IBullSwap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Smart",
              "address": "0x3a0fd6aa5088bad9957c6fe423d79219a620c0ab",
              "source": "CVQKM",
              "label": "Smart",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "APEE",
              "address": "0x3aa311c2ae013b523341e4b075b4cc5576784821",
              "source": "CVQKM",
              "label": "APEE",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DeFi",
              "address": "0x3acaa620d489abe7aaf938ab96e077b90d7a6825",
              "source": "CVQKM",
              "label": "DeFi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000",
              "balance": 10,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Amara",
              "address": "0x3c26c931595e04c0f7bab3e79c414bf1ae04e074",
              "source": "CVQKM",
              "label": "Amara Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VTK",
              "address": "0x3e5dbb495ea81b518963bc789ac8fda8ba825de0",
              "source": "CVQKM",
              "label": "VTK",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FOXY",
              "address": "0x3e81788c99cba525eef4d366823efadc76bb6d71",
              "source": "CVQKM",
              "label": "FOXY",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MWAN",
              "address": "0x3e9e3765ce2750439de917d358ca7526c5b62d85",
              "source": "CVQKM",
              "label": "MWAN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "tpjk",
              "address": "0x3efe27d1fd1eaa695aea472fe1c69a2f748cb71d",
              "source": "CVQKM",
              "label": "躺赢币",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "7000000000000000000000000000",
              "balance": 7000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Miloo",
              "address": "0x3f24dd09b5f8ac874bb556dca3a6e7a3659c4aea",
              "source": "CVQKM",
              "label": "Miloo",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TiBi",
              "address": "0x3f92b6fe4ab6d36d05c9a8a57b6cc89180350a96",
              "source": "CVQKM",
              "label": "TiBi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "90000000000000",
              "balance": 90000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MetaKing",
              "address": "0x40aba4c7c70b1672f7c18c6ada10f87679f4f71a",
              "source": "CVQKM",
              "label": "MetaKing",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BIDEN",
              "address": "0x40cbbab0de1a41c414c9b5a534268db58d46fb39",
              "source": "CVQKM",
              "label": "BIDEN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DogeMoon",
              "address": "0x4264d886a19d2d7b91a6d93f686eae5f9d878642",
              "source": "CVQKM",
              "label": "DogeMoon",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Tu7.org",
              "address": "0x442b656f5a5c3dd09790951810c5a15ea5295b51",
              "source": "CVQKM",
              "label": "Tu7.org",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "7127850000000000",
              "balance": 7127850,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PUNK",
              "address": "0x45f0d9eaeffb458f10c614325f92f1fbfa2e845a",
              "source": "CVQKM",
              "label": "PUNK",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GFGD",
              "address": "0x460ab311a612dcc6dc4434ceb0f9b8fadd6ab59c",
              "source": "CVQKM",
              "label": "GFGD",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RACAS",
              "address": "0x46ef0d40da966fc7fecff7f16853ced6f9de1cb4",
              "source": "CVQKM",
              "label": "RACAS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "APEE",
              "address": "0x47784b24d550599a474f46e8dfd853a07db45e5c",
              "source": "CVQKM",
              "label": "APEE",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DOGG",
              "address": "0x477c733942c710110b7bfdd978df89ee72ee8e46",
              "source": "CVQKM",
              "label": "DOGG",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AWA",
              "address": "0x483dcbb2af8988d758d2d4f7c2b141b97b749ce4",
              "source": "CVQKM",
              "label": "AWA Coin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "11000000000000000000000",
              "balance": 11000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Pheasant",
              "address": "0x4a4915a735bf344e0a99fba1a2bcefd5f6dac915",
              "source": "CVQKM",
              "label": "Pheasant currency",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "12396594444443560000000000000000",
              "balance": 12396594444443.56,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NFTT",
              "address": "0x4b8df12b69ad38500bb851db76e5e0fc666101ca",
              "source": "CVQKM",
              "label": "NFTT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PORTO",
              "address": "0x4d96c91c00c8c27cb4e3d9060d2265d7f71134ac",
              "source": "CVQKM",
              "label": "FC Porto Fan Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1586900000000",
              "balance": 15869,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "IMF",
              "address": "0x4eaf062348faa94bc1ee8fcb9177b6a6335d7ac5",
              "source": "CVQKM",
              "label": "IMF",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WOMAN",
              "address": "0x4f71f8f3dc7552fc1ff590259eaa66e0b4942825",
              "source": "CVQKM",
              "label": "WOMAN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OOO",
              "address": "0x4f727f7fcdeed6dbaa38818614a5504f1917fb6f",
              "source": "CVQKM",
              "label": "OOO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MELOS",
              "address": "0x4fb77265b33e6253d84361ee3fc2311a42912ead",
              "source": "CVQKM",
              "label": "MELOS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NFTS",
              "address": "0x4fdaf4d2484d008e9a5354249f09cb6145923ed2",
              "source": "CVQKM",
              "label": "NFTS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Revoland",
              "address": "0x50c1ee6c530da641737069d20ad1f295e211b254",
              "source": "CVQKM",
              "label": "Revoland",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BKN",
              "address": "0x52223693b669d62addc544720adc66b31fb12348",
              "source": "CVQKM",
              "label": "Brickken",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "47500000000000000000000",
              "balance": 47500,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GOALS",
              "address": "0x5265e2e26b09427b4ed9c83916480b7c656b6951",
              "source": "CVQKM",
              "label": "GOALS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "pp",
              "address": "0x52bbfb6b044a1a7c822c05d6d04344f453dd7f9a",
              "source": "CVQKM",
              "label": "pp",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DAOS",
              "address": "0x52c5e2fd98abd9277a21077f32cb88dacec1662c",
              "source": "CVQKM",
              "label": "DAOS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "💲 Income",
              "address": "0x52ce473974a2e93491ca759db0e23e6eac645f69",
              "source": "CVQKM",
              "label": "💲 Passive Income",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WGMT",
              "address": "0x533fac98be1cff7e70a07747653f0ea96abc4016",
              "source": "CVQKM",
              "label": "WGMT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "makiswap.io",
              "address": "0x53f952260518bffc4f803df125ff22799debec1a",
              "source": "CVQKM",
              "label": "makiswap.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "169990077",
              "balance": 169990077,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NFTS",
              "address": "0x56b5e512209164fe1a9c78f246cecd97f33d2522",
              "source": "CVQKM",
              "label": "NFTS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Candy",
              "address": "0x58bb0f4dd4158f145a0689bb3dcb8df6cbce2e08",
              "source": "CVQKM",
              "label": "CandySwap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "APP",
              "address": "0x5940479698386fcbdfef382767a90b31850c86f0",
              "source": "CVQKM",
              "label": "Apple",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "14258000000000000000000",
              "balance": 14258,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Token",
              "address": "0x5953bd43100b0ffdd8078f77fc591dc2832970d5",
              "source": "CVQKM",
              "label": "Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WAP",
              "address": "0x5963e74d72ff936d880cf5d1d029d64ea71baabc",
              "source": "CVQKM",
              "label": "WAP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GOQii",
              "address": "0x59bd5bc1f3863280cbfe106d39f05f84bada5620",
              "source": "CVQKM",
              "label": "GOQii",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BOY",
              "address": "0x5a0cff4e67441366d80a076ad9bd37c352d31244",
              "source": "CVQKM",
              "label": "Boy Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "400000000000000000000",
              "balance": 400,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpacePP",
              "address": "0x5a2b101cb6451183856a3465ebd2f4a94eaccc59",
              "source": "CVQKM",
              "label": "SpacePP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "UPFI",
              "address": "0x5ab9772c2e5bd7fd5ea97fe63ad1b05bcb08f198",
              "source": "CVQKM",
              "label": "UPFI Network",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MULTI",
              "address": "0x5b0c0224a66c79da7b92ed1d6e562c309aaa7951",
              "source": "CVQKM",
              "label": "MULTI",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LIVE",
              "address": "0x5b2f1c36ccca706257cf1d0ce1d630f91f68f9a7",
              "source": "CVQKM",
              "label": "LIVE",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NEST",
              "address": "0x5d1033dc32e5cedf5233f405d61c6e0595137ebc",
              "source": "CVQKM",
              "label": "NEST",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BLUEDOGE",
              "address": "0x5d4875bc345c05d415a0a019473d56fa3fbb0511",
              "source": "CVQKM",
              "label": "BlueDogeCoin.Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1099946346502482076",
              "balance": 1099946346.502482,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FINESCAM",
              "address": "0x5e180ffa0078c317d6df913cf063e215e8710c9d",
              "source": "CVQKM",
              "label": "Refinable SCAM",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Swap7.org",
              "address": "0x5e48c354a5da2b0a8c203518d0fc7b9c58cc9329",
              "source": "CVQKM",
              "label": "Swap7.org",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "713624319000000000",
              "balance": 713624319,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Nandi",
              "address": "0x5fbd64d44b302f26db217670de3d3502f611c399",
              "source": "CVQKM",
              "label": "Nandi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FRYCOIN",
              "address": "0x609b58f245cd010ba5830fe816038adee976b7dd",
              "source": "CVQKM",
              "label": "FRYCOIN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "4999000000000000000",
              "balance": 4.999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Mfer",
              "address": "0x61541f44dd581cf8fb653cb10166d36f33191dc4",
              "source": "CVQKM",
              "label": "Mfer",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Domains",
              "address": "0x6209fcda05842c878c537f48550bf14334110b9c",
              "source": "CVQKM",
              "label": "Domains",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MAD",
              "address": "0x623a52d4c7ae317f0b801c31c98b356d999cfe96",
              "source": "CVQKM",
              "label": "MAD",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Work",
              "address": "0x62eff0d339e1c04e1e1394ab8b3a975767816f83",
              "source": "CVQKM",
              "label": "Work",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HOLE",
              "address": "0x6354ceb57d3db9b557da114abe4423d2d7677383",
              "source": "CVQKM",
              "label": "HOLE",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DIVIDEND_TRACKER",
              "address": "0x642b97bce05bb766f1119a6ca1f80f9a8318f7da",
              "source": "CVQKM",
              "label": "DIVIDEND_TRACKER",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "9946800000000000000000",
              "balance": 9946.800000000001,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CA",
              "address": "0x6634f18a564223000d5405d7e2836ab8a3ce73f9",
              "source": "CVQKM",
              "label": "CA GameFi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EthWDao",
              "address": "0x664ac8c9c49a4d4a3fb3812ea933af5d83b0424f",
              "source": "CVQKM",
              "label": "EthereumPow DAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "13213535000000000000000000",
              "balance": 13213534.999999998,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OP",
              "address": "0x66599a4c5ac1b718052d25460ee019bf4f08a7a4",
              "source": "CVQKM",
              "label": "OP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpaceC",
              "address": "0x68080811a61d88b3459260c5551854b1856fd109",
              "source": "CVQKM",
              "label": "Space Coin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AABEK",
              "address": "0x68d1569d1a6968f194b4d93f8d0b416c123a599f",
              "source": "CVQKM",
              "label": "AABEK.net",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "20819314000000000",
              "balance": 20819314,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RSB",
              "address": "0x6a1562ef240cf21ea7296fe78f493cb6e75fbeab",
              "source": "CVQKM",
              "label": "RSB",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CEO",
              "address": "0x6aa6a1538b3eb50e37d7fdd5ad1892185793b04c",
              "source": "CVQKM",
              "label": "CEO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "STEPN",
              "address": "0x6b0d61502e1fe363d86dd70a5e791046579e4145",
              "source": "CVQKM",
              "label": "STEPN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GAL",
              "address": "0x6b32b08576a198f83cb55f999c0f0b53de8bbad6",
              "source": "CVQKM",
              "label": "GAL",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MAN",
              "address": "0x6cbbe367edb7339ccf5054309eabd90860efde67",
              "source": "CVQKM",
              "label": "MAN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "REI",
              "address": "0x6d243dc46e09caac7387e7222d9206fd46341633",
              "source": "CVQKM",
              "label": "REI Network",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EiFi",
              "address": "0x6e45f2e2dcdb48c75be9251ff2dc1553517c01e6",
              "source": "CVQKM",
              "label": "EiFi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Game",
              "address": "0x6ebbc0845deb362a3be71b17c639731d0570dc73",
              "source": "CVQKM",
              "label": "Game",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Marble DAO",
              "address": "0x6fee9a118dc2dce2d04cb59c47d47e2831b3c3ad",
              "source": "CVQKM",
              "label": "Marble DAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Cyan",
              "address": "0x70ef447c7c65f28357957ed01c303a09c2bd0871",
              "source": "CVQKM",
              "label": "Cyan",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BULL",
              "address": "0x72add749ddcfe9b749f50cbff76b59a2e6c80af5",
              "source": "CVQKM",
              "label": "Bull Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000000",
              "balance": 2000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Home",
              "address": "0x732205bb6d35ea807a57016250fee208b72e73c1",
              "source": "CVQKM",
              "label": "Home",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SOP",
              "address": "0x734265cefb9ba0354b839a9b628d3c9e1616d384",
              "source": "CVQKM",
              "label": "SOP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpaceBaby",
              "address": "0x73726cafd3321d7400758aee08fe48a128df9d2e",
              "source": "CVQKM",
              "label": "SpaceBaby",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "win",
              "address": "0x74b5656cdfc26badb6b76d820565707347911071",
              "source": "CVQKM",
              "label": "winb",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "8000000000000000000",
              "balance": 8,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PiPi",
              "address": "0x759acede86468cd8a77dbab10d2b7740781a9615",
              "source": "CVQKM",
              "label": "PiPi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FNDZ",
              "address": "0x76ea9abdf2db7e56ffe9fd0028d0afe5199d382b",
              "source": "CVQKM",
              "label": "FNDZ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TeaDAO",
              "address": "0x772e63bf77ef3b1b6737329b78feaaef58c75d79",
              "source": "CVQKM",
              "label": "TeaDAO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YUMI",
              "address": "0x78ca3b795e0f2a1061893d61ec5b76e5f2b44b6f",
              "source": "CVQKM",
              "label": "YUMI",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "334005058120620000000000",
              "balance": 334005.05812062003,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Gas",
              "address": "0x795f869a92339a7bc64f5e4eb4feaa6d9ee6a9ff",
              "source": "CVQKM",
              "label": "Gas",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Bifword",
              "address": "0x7a1ff388e96caf7c752ca9c7f64ae1a20bd95cfc",
              "source": "CVQKM",
              "label": "Bifword",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Swoop",
              "address": "0x7bbf2db9e5a0faa9c403c2a1c52defc12b166de6",
              "source": "CVQKM",
              "label": "Swoop",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GameBox",
              "address": "0x7d55ac7adbf0ba3cf5e4a9ed69a85a26e24ae4fd",
              "source": "CVQKM",
              "label": "GameBox",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ANML",
              "address": "0x7e5dda0c52139f8227b165503ba0eebc65b2881e",
              "source": "CVQKM",
              "label": "Animal Concerts",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ZBD",
              "address": "0x8268f08d4b39436e34ac4fba1ce9ca4214982700",
              "source": "CVQKM",
              "label": "ZBD Streamer",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Composable",
              "address": "0x831c2c0120df8d311de85a2ae0beb85c934cee81",
              "source": "CVQKM",
              "label": "Composable Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "YYswap",
              "address": "0x86219df03f5bc10740240825627f7ffea3c46fef",
              "source": "CVQKM",
              "label": "YYswap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LORT",
              "address": "0x8850059ecb52ad1a0385449083f2ba05728ebef7",
              "source": "CVQKM",
              "label": "LORT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "See",
              "address": "0x89f06caa9e2be219c431e0b6fafcb9422a7f537a",
              "source": "CVQKM",
              "label": "See",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PDD",
              "address": "0x8af6ebaececf714627376897af7d09869b3bf229",
              "source": "CVQKM",
              "label": "PDD",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Open",
              "address": "0x8e876f6cd486508814cb5e2ffaeeca0bce58f506",
              "source": "CVQKM",
              "label": "Open",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MEME ",
              "address": "0x8ee61709375c99d159272f42fc624094c4cc3786",
              "source": "CVQKM",
              "label": "MEME ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RDAC",
              "address": "0x8f1f22f562f3dbacf7fa279bb283d1acdab0b5be",
              "source": "CVQKM",
              "label": "RDAC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DAF",
              "address": "0x8fb1a59ca2d57b51e5971a85277efe72c4492983",
              "source": "CVQKM",
              "label": "Diamonds Are Forever",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "2000000",
              "balance": 2,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Space Dao",
              "address": "0x94cb920a955d1132398f083a1aa43c524a2463c0",
              "source": "CVQKM",
              "label": "Space Dao",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KiN",
              "address": "0x95626e72ce6203c828700e250f00347fcc92812b",
              "source": "CVQKM",
              "label": "KiN",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Nexo",
              "address": "0x968c1978d47dad59935ac7c03c1282875d20c498",
              "source": "CVQKM",
              "label": "Nexo",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GMM",
              "address": "0x97356795ae277be74b77ebc2dc9e8581739a8d61",
              "source": "CVQKM",
              "label": "GMM",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Phi",
              "address": "0x9b0025341c8343b16ab3a71e86907b3177664191",
              "source": "CVQKM",
              "label": "Phi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MiniDoge",
              "address": "0x9b1ba20ae9335197c72be094d4fa300d4ef95351",
              "source": "CVQKM",
              "label": "Mini-Doge.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "500000000000000000000000000000",
              "balance": 500000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Flare",
              "address": "0x9b1f68a0ed86ee4b9a4b5105f3be4e46c6e2a468",
              "source": "CVQKM",
              "label": "Flare Network",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Bin",
              "address": "0x9cc61963e82ca095762c6d251ba945366d4a3c27",
              "source": "CVQKM",
              "label": "Bin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CeDeFi",
              "address": "0x9d2cadab398fa356c24333cea3f4dad65288ddff",
              "source": "CVQKM",
              "label": "CeDeFi",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "GQ",
              "address": "0xa06065da9111fca5710d261584ac8244c67be1cb",
              "source": "CVQKM",
              "label": "GQ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SSA",
              "address": "0xa0d206ec22e494c52d4f98cac8e186e1f0c31d00",
              "source": "CVQKM",
              "label": "SSA",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Monderna",
              "address": "0xa173a9a6623e17266e1585c03f491e5c63327a48",
              "source": "CVQKM",
              "label": "Monderna",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Fuse",
              "address": "0xa2e2a7b83c7b8da73156a4574ba7b6ae4a3cac88",
              "source": "CVQKM",
              "label": "Fuse",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "pwx",
              "address": "0xa4a8208cd4dcc50484fff7e2606681f93048bc09",
              "source": "CVQKM",
              "label": "paowuxian",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "10000022000000",
              "balance": 100000.22,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XYZ",
              "address": "0xa6267c5266354e2ef7e7407d6dc39eb97479c873",
              "source": "CVQKM",
              "label": "XYZ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NNFT",
              "address": "0xa63c18215e5c31740e1f78ca74d3c9ef681fd4bb",
              "source": "CVQKM",
              "label": "NNFT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "1000X",
              "address": "0xa6a2755d105545d374035cf170f0c34f3995da67",
              "source": "CVQKM",
              "label": "1000X",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NING",
              "address": "0xa6a750193be387e8e51272984a3b89f1be44fed8",
              "source": "CVQKM",
              "label": "NING",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "90000000000",
              "balance": 90,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FLOKI",
              "address": "0xa6bcdf134aa42f171d796a96234f8d95bfd7fdc7",
              "source": "CVQKM",
              "label": "METAFLOKISHIBA",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "25000000000000000000000000",
              "balance": 25000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TVL",
              "address": "0xa795190a5656005494bc374a05f54f54b0eff5cb",
              "source": "CVQKM",
              "label": "TVL",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PPO",
              "address": "0xa8d960032f8c6080598627e3d1a68ad74775106b",
              "source": "CVQKM",
              "label": "PPO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PEACE",
              "address": "0xaab1413be4986e68502b3dc8eee355b897c32a8d",
              "source": "CVQKM",
              "label": "PEACE",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TC",
              "address": "0xaaf6972bb2d6c9c211b8938d8c710eac26bc937d",
              "source": "CVQKM",
              "label": "TWOCATS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000000000",
              "balance": 1000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VDS",
              "address": "0xab68bcf39fa3134fc59d5c20ee3f90999d53dde1",
              "source": "CVQKM",
              "label": "VDS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PYC",
              "address": "0xab7abe3343fabb2cc083c6953907c36582139020",
              "source": "CVQKM",
              "label": "PYC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WWKM",
              "address": "0xacac66577f4616e337753e425d9f2b73abbafae6",
              "source": "CVQKM",
              "label": "WWKM",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "5000000000000000000",
              "balance": 5,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "seed",
              "address": "0xadbdb43205c5f3b9dacce26c35857b2d763f55c1",
              "source": "CVQKM",
              "label": "seed",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SAND",
              "address": "0xaf322c10333d27f47260fa94df1d2d74edaeee57",
              "source": "CVQKM",
              "label": "Sand",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "594356608955149097199",
              "balance": 594.3566089551491,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Zepe.io",
              "address": "0xb0557906c617f0048a700758606f64b33d0c41a6",
              "source": "CVQKM",
              "label": "Zepe.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "750000000000000000000000",
              "balance": 750000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Moonbirds",
              "address": "0xb064bdc4dd134e2360470d99d9cda3d1360cf55a",
              "source": "CVQKM",
              "label": "Moonbirds",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MeMe",
              "address": "0xb0e4453c2a26f93e3dee12bf85a806a57ebd2f57",
              "source": "CVQKM",
              "label": "MeMe",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Web3Coin",
              "address": "0xb0f8d835e957ceb92d40fa110c9428440420f061",
              "source": "CVQKM",
              "label": "Web3Coin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LUNCH",
              "address": "0xb15213970a50c8cf2977e906c7a41d50f0d0d4d0",
              "source": "CVQKM",
              "label": "LUNCH",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "436590000000000000000000",
              "balance": 436590,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FLUX",
              "address": "0xb16600c510b0f323dee2cb212924d90e58864421",
              "source": "CVQKM",
              "label": "GoFlux.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "950000000000000000000000",
              "balance": 950000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MBB",
              "address": "0xb1725faf4acba5ad5e0d0c3e7829ed4622d8f44d",
              "source": "CVQKM",
              "label": "MBB",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CCC",
              "address": "0xb2532dd1d92146f366ae2ab755e829cd83309b36",
              "source": "CVQKM",
              "label": "CCC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NFTY",
              "address": "0xb35448d6017b4f6612b651c3f60a05ca07f9b21b",
              "source": "CVQKM",
              "label": "NFTY",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ESMA",
              "address": "0xb3aa0f61be002ff27f6ba13fbd3c32596eff492e",
              "source": "CVQKM",
              "label": "ESMA",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "XCH5.io",
              "address": "0xb3ec4e17c1c0079d977354875ab924c088325306",
              "source": "CVQKM",
              "label": "XCH5.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "821112000000000",
              "balance": 821112,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BAC",
              "address": "0xb3ff89a32025cdacf0e112ebf8ee24dc236c43b2",
              "source": "CVQKM",
              "label": "BAC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MEMK",
              "address": "0xb43e3ff077a85e082a68da4c9c210ee2c1cd0613",
              "source": "CVQKM",
              "label": "MemeKiller",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "10000000000000044859",
              "balance": 10000000000.000046,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Sade ",
              "address": "0xb5badd7a725532dfee0bada933034e534cc485f5",
              "source": "CVQKM",
              "label": "Sade Swap ",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "USDs",
              "address": "0xb7ed2c5514496591142f3098ef40ee6f56c3a45c",
              "source": "CVQKM",
              "label": "USD stablecoin v1.0 produced by sha network",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BEAM",
              "address": "0xb8220fc541e619331cf0d6363dea329844a7c0f4",
              "source": "CVQKM",
              "label": "Beamswap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Fairy",
              "address": "0xba440a82f19106e791994105f043d3fd9ba4926a",
              "source": "CVQKM",
              "label": "FairySwap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "95000000000000",
              "balance": 95000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SDJT",
              "address": "0xbb368656efa67361b0063fe0f11cb08bd460246d",
              "source": "CVQKM",
              "label": "SDJT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000",
              "balance": 0.1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BAYC",
              "address": "0xbc42a45a62f1516764e61ad4bd335d3388620003",
              "source": "CVQKM",
              "label": "BAYC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AIR",
              "address": "0xbc6675de91e3da8eac51293ecb87c359019621cf",
              "source": "CVQKM",
              "label": "BestAir.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1921604000000000000000000",
              "balance": 1921604,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "EINSTEIN",
              "address": "0xbd65a197408230247f05247a71d1a9aea9db0c3c",
              "source": "CVQKM",
              "label": "Polkadog-V2.0",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000000000",
              "balance": 100,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SMIX",
              "address": "0xbe2f72d41bd7dbab675ddb74944d10e039a8f4ad",
              "source": "CVQKM",
              "label": "SafeMOONMix Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "9876543210123456789",
              "balance": 9876543210.123457,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BUY_BABYLONDON_NOW",
              "address": "0xbe474de8badf08d227252baf38621ea18def9db9",
              "source": "CVQKM",
              "label": "BUY BABYLONDON NOW",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1100",
              "balance": 11,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NCZ",
              "address": "0xbe5614b3b12bcb7c8034a278eedff6715f5bfe3c",
              "source": "CVQKM",
              "label": "Neural Convolutional Zenith",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "10000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 7,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Moneybox",
              "address": "0xbe7e74ff8a46c712c313e75683c0861cf5f2ad34",
              "source": "CVQKM",
              "label": "Moneybox",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SpaceP",
              "address": "0xc20e4f1ecef3025328599aaefcdfe621a1a4c656",
              "source": "CVQKM",
              "label": "SpaceP",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BORA",
              "address": "0xc242d1baadead15d455c593c2a8d462db5e35adf",
              "source": "CVQKM",
              "label": "BORA",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CRE",
              "address": "0xc2c14affaddee38d7375c400d144251a87d2c34a",
              "source": "CVQKM",
              "label": "Creator Protocol",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "500000000000000",
              "balance": 500000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Coow",
              "address": "0xc3bdc9cb9f8b9bd90a76d1c4ed2a85a2df88efad",
              "source": "CVQKM",
              "label": "Coow",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AMX",
              "address": "0xc564991fe1daa4022e88d470b2eeaca0c49bd425",
              "source": "CVQKM",
              "label": "Amox",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000",
              "balance": 50,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Moderna",
              "address": "0xc5ebe360f2e96a53896d52c8fd4b66825d045924",
              "source": "CVQKM",
              "label": "Moderna",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DAOS",
              "address": "0xc6bd95b33a9d059ced283eba3143612b57ffbd78",
              "source": "CVQKM",
              "label": "DAOS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "COMFY",
              "address": "0xc737b44cb0aa18815a1f6918eb338dee7e7e6bd7",
              "source": "CVQKM",
              "label": "ComfyToken",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "426007471989",
              "balance": 426.007471989,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OTO",
              "address": "0xc96f81f19783a2c58d01dd45aac207385f1a3419",
              "source": "CVQKM",
              "label": "OTO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "96000000000000",
              "balance": 96000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Blue",
              "address": "0xc973a2981ad63c57a3de05e5e021202f16caf123",
              "source": "CVQKM",
              "label": "Blue",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Finoa",
              "address": "0xc9bae6c810ec8d13c4946b8f8ca25b17f1f6fe8b",
              "source": "CVQKM",
              "label": "Finoa",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Gwei",
              "address": "0xca6dd57416ed6533a80f169a0e7304eb069d5117",
              "source": "CVQKM",
              "label": "Gwei",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "swap8.io",
              "address": "0xcaee79616cffeb53fdda5792742a5c084f879dec",
              "source": "CVQKM",
              "label": "swap8.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "980000000000000000000000",
              "balance": 980000.0000000001,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Babygirl",
              "address": "0xcd206b70854ee05dc576e23ba244ded0a448ecf6",
              "source": "CVQKM",
              "label": "Babygirl",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FGDX",
              "address": "0xcdab01df70274667d4df5374246ed81ba2676428",
              "source": "CVQKM",
              "label": "FGDX",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Definance",
              "address": "0xd1acb91e5b9a99a73bdfc1c8701abfa71ffc1464",
              "source": "CVQKM",
              "label": "Definance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MNEB",
              "address": "0xd22202d23fe7de9e3dbe11a2a88f42f4cb9507cf",
              "source": "CVQKM",
              "label": "Minereum BSC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "15000000000000",
              "balance": 150000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0xd22202d23fe7de9e3dbe11a2a88f42f4cb9507cf.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "FGDS",
              "address": "0xd4e187b83b67623055d922bf04ef9dc83e4c2b32",
              "source": "CVQKM",
              "label": "FGDS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Mand",
              "address": "0xd521e98ad9861ab9e406305a7064998556aefd8d",
              "source": "CVQKM",
              "label": "Mand",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Twister",
              "address": "0xd553ada8f1e164d6de5d9e751cd180a7d4e4fe22",
              "source": "CVQKM",
              "label": "Twister",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DeFi",
              "address": "0xd7f113106a7be2c1a326281f6df7ef777b7e0865",
              "source": "CVQKM",
              "label": "Decentralized Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "20000000000000",
              "balance": 20000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Burrow",
              "address": "0xd904dfc3abea0949f9ea205d5bace81abaaad8fa",
              "source": "CVQKM",
              "label": "Burrow",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Confirm",
              "address": "0xdb629793132e90b0a1f057dff4e7e408dd180e28",
              "source": "CVQKM",
              "label": "Confirm",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "QCB",
              "address": "0xdc345c5b59f42559c497260a0e3211b482bf4bbe",
              "source": "CVQKM",
              "label": "QCB",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RPG",
              "address": "0xdd0a6937df77e29fe579db31b0a2853ed8096b5a",
              "source": "CVQKM",
              "label": "RPG",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SPill",
              "address": "0xde849abca172b5f2208e6dfca2ec98999c29d529",
              "source": "CVQKM",
              "label": "SPill",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PORTO",
              "address": "0xe18634b4a31e6d03b58899849e132de3f83952ad",
              "source": "CVQKM",
              "label": "FC Porto Fan Token",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "15869000000000000000000",
              "balance": 15869,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "NNFT",
              "address": "0xe1d02600e583b400683a4da583eeba66a2f65ba8",
              "source": "CVQKM",
              "label": "NNFT",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Revest",
              "address": "0xe27d65ba103eafac5b1204b5552ff3beb2793417",
              "source": "CVQKM",
              "label": "Revest Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "COCO",
              "address": "0xe310de07306e3aed2a8124b2cc8998879d7a634a",
              "source": "CVQKM",
              "label": "COCO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 6,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LMR",
              "address": "0xe4f1a73640b2151b922ecafcdc27810a5636d907",
              "source": "CVQKM",
              "label": "Lumerin",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MVEDA",
              "address": "0xe73bb2b15f0a5f45729a9f6c4a9a51b380bf0dff",
              "source": "CVQKM",
              "label": "Medical Veda",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000",
              "balance": 1000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RBX",
              "address": "0xe833dc9b5a21dabc3ed8559858caa92279ac54dc",
              "source": "CVQKM",
              "label": "METAROBOX",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "50000000000000000000000",
              "balance": 49999.99999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "OOE",
              "address": "0xe99512305bf42745fae78003428dcaf662afb35d",
              "source": "CVQKM",
              "label": "OpenOcean",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CYY",
              "address": "0xe9c9dfbc57469b3f75b15ae63cd7d63a4ebbb95d",
              "source": "CVQKM",
              "label": "CYY",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Face",
              "address": "0xea4a59846e5cf43378dd300db259ea4306779118",
              "source": "CVQKM",
              "label": "Face",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "ABOYS",
              "address": "0xeade8e37700a34b196142d9962858d2a137e6eb8",
              "source": "CVQKM",
              "label": "rocketboys.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "18000000000000000000000000",
              "balance": 18000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AAAA",
              "address": "0xeb5dc66790adb99f5b86da47df7ecdf7426d43e7",
              "source": "CVQKM",
              "label": "AAAA",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Apollo",
              "address": "0xed04ab2a8c3d501a287d477c2589616765a48d36",
              "source": "CVQKM",
              "label": "APOLLO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000000000",
              "balance": 1000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CFF",
              "address": "0xed19e92416bcf0c70ae83f4eb8e2374c5516a7f1",
              "source": "CVQKM",
              "label": "CFF",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "oner",
              "address": "0xeeb1066f75d123a089a601058d6d2b43d1af6947",
              "source": "CVQKM",
              "label": "oner",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AGMC.io",
              "address": "0xef27b9cb67aa93ec3494a60f1ea9380e86175b26",
              "source": "CVQKM",
              "label": "AGMC.io",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "800000000000000000000000",
              "balance": 799999.9999999999,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BBS",
              "address": "0xef8f4b2e1364b6f52c944e7d967c6824d8d3827e",
              "source": "CVQKM",
              "label": "BBS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KO",
              "address": "0xef9cb0ab0ebd9b502dbf60b1f59ea4c31ec0ba6f",
              "source": "CVQKM",
              "label": "KO",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MetaXDao",
              "address": "0xf107822eabd76beaf95777c5b5e43cfc85179162",
              "source": "CVQKM",
              "label": "MetaXDao",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VRR",
              "address": "0xf295dcd9edebd8ce3030375f12205a47ecb9aab8",
              "source": "CVQKM",
              "label": "VRR",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "TB Network",
              "address": "0xf435847f05e586ff320d03af9444c4420d709a5f",
              "source": "CVQKM",
              "label": "TB",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CUOR",
              "address": "0xf4d938b8926dcb26bfb092a63cc67d5392f8766c",
              "source": "CVQKM",
              "label": "Cuore",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "LOG",
              "address": "0xf5ce0c13f9287d37adea719628821e4c20edaa2a",
              "source": "CVQKM",
              "label": "LOGOS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "915485343000000000",
              "balance": 0.915485343,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WOTC",
              "address": "0xf7b16263c9addf80daead39ffaabf4c741d4864b",
              "source": "CVQKM",
              "label": "WOTC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Definance",
              "address": "0xf7c68cceef8a101030590b3e9b9d97c18d001300",
              "source": "CVQKM",
              "label": "Definance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "VOVO",
              "address": "0xfb00b3878775025c367f304ce1504f3466d95d0d",
              "source": "CVQKM",
              "label": "VoVo Finance",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "99000000000000",
              "balance": 99000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "BOYS",
              "address": "0xfc2f85ed3ed014e1ec4c42c0b466fd1afd2e79bc",
              "source": "CVQKM",
              "label": "BOYS",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DOG",
              "address": "0xfc3f3cdc8609491d46b138228bd642bad38041a6",
              "source": "CVQKM",
              "label": "DOG",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "RAC",
              "address": "0xfccedb5bd934d4acb26ca3a295fc8979b55a5a99",
              "source": "CVQKM",
              "label": "RAC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SSD",
              "address": "0xfcd3ce056abe474829cae3c490a22c944824d484",
              "source": "CVQKM",
              "label": "SSD",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "PUD",
              "address": "0xfdb73a1ce24298670e636e71ef90abf8cd45f9d9",
              "source": "CVQKM",
              "label": "PuddingSwap",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CRE",
              "address": "0xff398b348d91d64f3e0879d8678949dc9818d5f2",
              "source": "CVQKM",
              "label": "Creator Protocol",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "500000000000000",
              "balance": 500000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MMC",
              "address": "0xff63701710dc3c412cf670d3de899ff97ad4eb10",
              "source": "CVQKM",
              "label": "MMC",
              "network": "bsc",
              "protocol": "tokens",
              "balanceRaw": "100000000000000",
              "balance": 100000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 9,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            }
          ]
        },
        {
          "name": "polygon",
          "data": [
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "WETH",
              "address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
              "source": "CVQKM",
              "label": "Wrapped Ether",
              "network": "polygon",
              "protocol": "aave-v2",
              "balanceRaw": "1067784349968239",
              "balance": 0.001067784349968239,
              "balanceUSD": 1.2948148432250912,
              "price": 1212.6183,
              "decimals": 18,
              "imageUrl": "https://assets.dev.cloud.canvas.co/tokens/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.1662509497373.png",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CPR",
              "address": "0xaa404804ba583c025fa64c9a276a6127ceb355c6",
              "source": "CVQKM",
              "label": "CIPHER",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "100800",
              "balance": 1008,
              "balanceUSD": 0.02525877144,
              "price": 0.000025058305,
              "decimals": 2,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "USDC",
              "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
              "source": "CVQKM",
              "label": "USD Coin (PoS)",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "10000",
              "balance": 0.01,
              "balanceUSD": 0.010011644,
              "price": 1.0011644,
              "decimals": 6,
              "imageUrl": "https://assets.coingecko.com/coins/images/17249/small/amUSDC_2x.png?1626941306",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CCI",
              "address": "0x21a89ff1568053622462e109136d84fcf68db7c8",
              "source": "CVQKM",
              "label": "GemFi.vip",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "3000000000000000000",
              "balance": 3,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SDOGS",
              "address": "0x320b10972a0807fbd6743daef2d6994737292640",
              "source": "CVQKM",
              "label": " StrayDogs",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "25000000000000000000000000",
              "balance": 25000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "CKIE",
              "address": "0x3c0bd2118a5e61c41d2adeebcb8b7567fde1cbaf",
              "source": "CVQKM",
              "label": "Cookie",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "24826917000000",
              "balance": 0.000024826917,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "DIG",
              "address": "0x40a79c66306c4d5c06b24c3aeb7ebc59af1ffac6",
              "source": "CVQKM",
              "label": "DIGITS",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000000000",
              "balance": 10000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MM",
              "address": "0x5647fe4281f8f6f01e84bce775ad4b828a7b8927",
              "source": "CVQKM",
              "label": "Million (PoS)",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "10000000000000000",
              "balance": 0.01,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "JustForFun",
              "address": "0x5b56a425f8b45d82ebb57dca4f02528409ae763b",
              "source": "CVQKM",
              "label": "JustForFun",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "12340000",
              "balance": 1234,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 4,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "HMC",
              "address": "0x6df5c1da0310a0725b919579d06de427cb578c83",
              "source": "CVQKM",
              "label": "HmmCoin",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "1000000000000000000",
              "balance": 1,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "AAX",
              "address": "0x8ae127d224094cb1b27e1b28a472e588cbcc7620",
              "source": "CVQKM",
              "label": "aaxexchange.org",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "26347543096503948200000000000",
              "balance": 26347543096.50395,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "SRT",
              "address": "0x8f99f7549ed5ab18cd6a5e5d85b97badda32ab2a",
              "source": "CVQKM",
              "label": "strot",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "110000000000000000000000",
              "balance": 110000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "KAI",
              "address": "0x98689df2a96de1810e2cceb8bd8405ed7172b43e",
              "source": "CVQKM",
              "label": "KAWAII",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "75350000000000000000000",
              "balance": 75350,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "",
              "address": "0x9ebb5aafb48a9ef5742a8f2f9bcb27cd00f45478",
              "source": "CVQKM",
              "label": "cryptocurrency",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "2000000000000000000",
              "balance": 2000000000000000000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 0,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "Zers.io",
              "address": "0xd7f1d4f5a1b44d827a7c3cc5dd46a80fade55558",
              "source": "CVQKM",
              "label": "Zers.io",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "11287000000000000000000",
              "balance": 11287,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 18,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            },
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MNEP",
              "address": "0xecdff6fd2f184f8b8987682a10aa6890ca74c5a8",
              "source": "CVQKM",
              "label": "polygon.minereum.com",
              "network": "polygon",
              "protocol": "tokens",
              "balanceRaw": "30000000000000",
              "balance": 300000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            }
          ]
        },
        {
          "name": "avalanche",
          "data": [
            {
              "walletId": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "symbol": "MNEAV",
              "address": "0xf9d922c055a3f1759299467dafafdf43be844f7a",
              "source": "CVQKM",
              "label": "Minereum AVAX",
              "network": "avalanche",
              "protocol": "tokens",
              "balanceRaw": "30000000000000",
              "balance": 300000,
              "balanceUSD": 0,
              "price": 0,
              "decimals": 8,
              "imageUrl": "",
              "walletAddress": "0x00000000219ab540356cbb839cbe05303d7705fa",
              "category": "wallet",
              "updatedAt": "2023-01-03T22:09:03.902Z",
              "status": true
            }
          ]
        }
      ]
    },
    {
      "name": "pool",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "staked",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "deposit",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "claimable",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "debt",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "collateral",
      "totalBalance": 0,
      "networks": []
    },
    {
      "name": "other",
      "totalBalance": 0,
      "networks": []
    }
  ],
  "lastUpdate": "2023-01-03T22:08:40.910Z",
  "status": "complete"
}`;