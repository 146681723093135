import { makeStyles, AppBar, Hidden, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

import HamburgerIcon from "@assets/nav/closed-icon.svg";
import canvasLogo from "@assets/logos/canvas-logo.svg";

interface IAppBarLayoutStyles {
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) =>
({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: ({ drawerWidth }: IAppBarLayoutStyles) => `calc(100% - ${drawerWidth}px)`,
      marginLeft: ({ drawerWidth }: IAppBarLayoutStyles) => drawerWidth,
    },
    background: '#333042',
  },
  menuButton: {
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoImgContainer: {
    marginRight: 15,
    '& img': {
      height: 32,
      width: 28,
    },
  },
  logoTitle: {
    color: '#fff',
  },
  toolbar: {
    position: 'relative',
  },
  beta: {
    width: 44,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F37222',
    color: '#Fff',
    fontSize: "0.875rem",
    borderRadius: 4,
    marginLeft: 15,
  },
})
);

interface IAppBarLayout {
  drawerWidth: number;
  setMobileOpen: (value: boolean) => void;
  mobileOpen: boolean;
}

export function AppBarLayout({ drawerWidth, setMobileOpen, mobileOpen }: IAppBarLayout) {
  const classes = useStyles({ drawerWidth });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      { /* @ts-ignore */}
      < Hidden smUp implementation="js" >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <img src={HamburgerIcon} alt="" />
            </IconButton>
            <div className={classes.logoWrapper}>
              <div className={classes.logoImgContainer}>
                <img src={canvasLogo} alt="logo" />
              </div>
              <Typography variant="h2" className={classes.logoTitle}>CANVAS</Typography>
              <div className={classes.beta}>BETA</div>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden >
    </>
  );
}
