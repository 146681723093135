import { makeStyles } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

interface ICodeBox {
  code: string;
  wrap: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '0 0 100%',
  },
  paper: {
    padding: theme.spacing(3),
  },
  code: {
    fontFamily: '"Roboto Mono"', width: '100%', height: '280px',
  }
}));

export const CodeBox = ({ code, wrap }: ICodeBox) => {
  const classes = useStyles();
  return (
    <textarea className={classes.code} defaultValue={code} cols={999} wrap={wrap ? 'soft' : 'off'} disabled />
  );
};