
import { makeStyles } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}));

export const Success = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2>Subscription Success!</h2>
    </div>
  );
};
