import { useState } from "react";
import { getPortalLink } from "@services/billing.service";

import { CanvasButton } from "@shared/UI/CanvasButton";
import { CanvasSkeleton } from "@shared/UI/CanvasSkeleton";

import { makeStyles, Paper, Grid } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

const BILLING_REDIRECT_URL = process.env.REACT_APP_BILLING_REDIRECT_URL || '';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block'
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(6)
  },
  content: {
    marginBottom: theme.spacing(3)
  },
  gridLabel: {
    textAlign: 'right',
    fontWeight: 500,
  },
  gridValue: {
    textAlign: 'left',
    fontWeight: 300,
  }
}));

export const Basic = (props: any) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.gridLabel}>Plan: </Grid><Grid item xs={6} className={classes.gridValue}>{props.details.subscription.plan.nickname}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Amount: </Grid><Grid item xs={6} className={classes.gridValue}>{parseFloat(props.details.subscription.plan.amount_decimal) / 100} {props.details.subscription.currency.toUpperCase()}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Start Date: </Grid><Grid item xs={6} className={classes.gridValue}>{new Date(props.details.subscription.start_date * 1000).toLocaleString()}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Current Period: </Grid><Grid item xs={6} className={classes.gridValue}>{new Date(props.details.subscription.current_period_end * 1000).toLocaleString()}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Subscription: </Grid><Grid item xs={6} className={classes.gridValue}>{props.details.subscription.status}</Grid>
      {props.details.cancel_at_period_end && (
        <>
          <Grid item xs={6}>Cancelled at Period End</Grid><Grid item xs={6}>{props.details.cancel_at_period_end.toString()}</Grid>
        </>
      )}
    </Grid>
  );
};

export const Metered = (props: any) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.gridLabel}>Plan: </Grid><Grid item xs={6} className={classes.gridValue}>{props.details.subscription.plan.nickname}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Amount: </Grid><Grid item xs={6} className={classes.gridValue}>{parseFloat(props.details.subscription.plan.amount_decimal) / 100} {props.details.subscription.currency.toUpperCase()} {props.details.subscription.plan?.billing_scheme?.replace('_', ' ')}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Start Date: </Grid><Grid item xs={6} className={classes.gridValue}>{new Date(props.details.subscription.start_date * 1000).toLocaleString()}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Current Period: </Grid><Grid item xs={6} className={classes.gridValue}>{new Date(props.details.subscription.current_period_end * 1000).toLocaleString()}</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Current Usage: </Grid><Grid item xs={6} className={classes.gridValue}>{props.details.usage?.data?.length > 0 ? props.details.usage?.data[0].total_usage : ''} units ( ${props.details.usage?.data[0].total_usage * parseFloat(props.details.subscription.plan.amount_decimal) / 100} )</Grid>
      <Grid item xs={6} className={classes.gridLabel}>Subscription: </Grid><Grid item xs={6} className={classes.gridValue}>{props.details.subscription.status}</Grid>
      {props.details.cancel_at_period_end && (
        <>
          <Grid item xs={6}>Cancelled at Period End</Grid><Grid item xs={6}>{props.details.cancel_at_period_end.toString()}</Grid>
        </>
      )}
    </Grid>
  );
};

export const Status = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const doManage = () => {
    setIsLoading(true);
    getPortalLink(BILLING_REDIRECT_URL)
      .then(link => {
        // setIsLoading(false);
        window.location.href = link;
      })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <h3>Subscription Status</h3>
      <div className={classes.content}>
        {props.detailsLoading && (
          <CanvasSkeleton style={{ height: '150px' }} />
        )}
        {!props.detailsLoading && props?.details && !props?.details?.subscription?.plan?.aggregate_usage && (
          <Basic details={props.details} />
        )}
        {!props.detailsLoading && props?.details?.subscription?.plan?.aggregate_usage === 'sum' && (
          <Metered details={props.details} />
        )}
      </div>
      <CanvasButton disabled={isLoading || props.detailsLoading || !props.details} loading={isLoading} onClick={() => doManage()} >manage</CanvasButton>
    </Paper>
  );
};
