import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import useAuthStore from '@hooks/useAuthStore';

import canvasLogo from '@assets/logos/canvas-logo.svg';
import secureIcon from '@assets/nav/secure-icon.svg';
import invoiceIcon from '@assets/nav/invoice-icon.svg';
import settingsIcon from '@assets/nav/settings-icon.svg';
import logoutIcon from '@assets/nav/logout-icon.svg';
import loginIcon from '@assets/nav/login-icon.svg';

import { MenuItem, MenuItemData } from './MenuItem';
import { LogoutItem } from './LogoutItem';

import { List, makeStyles, Typography } from '@material-ui/core';

import { version } from '../../version';
const APP_NAME = 'Prime API';
const APP_VERSION = version;

console.log(`${APP_NAME} v${APP_VERSION}`)

interface IMenu {
  isOpen?: boolean;
  onClose?: () => void;
  isMobile?: boolean;
}

const useStyles = makeStyles(() => ({
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props: IMenu) => (props.isOpen ? '0 30px' : '0 10px'),
  },
  divider: {
    height: 1,
    background: '#5C5968',
  },
  logoWrapper: {
    display: 'flex',
    height: 105,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid #5C5968',
    marginBottom: 33,
    cursor: 'pointer',
    padding: 10,
  },
  mobileLogo: {
    padding: 10,
  },
  logoImgContainer: {},
  logoTitle: {
    marginTop: 8,
    color: '#fff',
    letterSpacing: 1,
  },
  logoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuWrapper: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
    overflow: 'scroll',
    display: 'flex',
    scrollbarColor: 'rgb(51, 48, 66) rgb(51, 48, 66)',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  beta: {
    width: 44,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F37222',
    color: '#Fff',
    fontSize: '0.875rem',
    borderRadius: 4,
    top: 12,
    right: 60,
    marginTop: (props: IMenu) => (props.isOpen ? '0' : '10px'),
    position: (props: IMenu) => (props.isOpen ? 'absolute' : 'initial'),
  },
  version: {
    fontSize: '0.85em',
    textAlign: 'center',
    color: '#999',
    fontWeight: 400
  }
}));

export function Menu({ isOpen, onClose, isMobile }: IMenu) {
  const classes = useStyles({ isOpen });

  const { isAuthorized, logout } = useAuthStore();
  const navigate = useNavigate();

  const overviewUrl = isAuthorized ? '/overview' : '/signin';
  const mainIconUrl = overviewUrl;

  const doSignIn = async () => {
    try {
      navigate('/');
    } catch (e) { }
  };

  const menu: Array<MenuItemData> = [
    {
      icon: secureIcon,
      name: 'API Token',
      to: '/token',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: invoiceIcon,
      name: 'Billing',
      to: '/billing/pricing',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: settingsIcon,
      name: 'Settings',
      to: '/d/settings',
      onClick: onClose,
      isPublic: false,
    },
  ];

  return (
    <>
      <div className={clsx(classes.logoWrapper, isMobile && classes.mobileLogo)}>
        <div className={classes.logoImgContainer} onClick={() => navigate(mainIconUrl)}>
          <img src={canvasLogo} alt='logo' />
        </div>
        <div className={classes.beta}>BETA</div>
        {isOpen && (
          <>
            <Typography variant='h3' className={classes.logoTitle}> CANVAS</Typography>
            <div className={classes.version}>{APP_NAME} v{APP_VERSION}</div>
          </>
        )}
      </div>
      <div className={classes.menuWrapper}>
        <List className={classes.containerItem}>
          {menu.filter((menuItem) => isAuthorized || menuItem.isPublic).map((menuItem) => (
            <MenuItem
              isDrawerOpen={isOpen}
              menuItem={menuItem}
              key={menuItem.name}
              id={menuItem.name}
              className={menuItem.className || ''}
            />
          ))}
        </List>
        {isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: logoutIcon,
                name: 'Logout',
                onClick: logout,
              }}
              key={'Logout'}
              id={'Logout'}
              openSideBar={true}
            />
          </List>
        )}
        {!isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: loginIcon,
                name: 'Login',
                onClick: doSignIn,
              }}
              key={'Login'}
              id={'Login'}
              openSideBar={true}
            />
          </List>
        )}
      </div>
    </>
  );
}
