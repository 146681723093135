import { Router } from "./Router";

import { datadogRum } from '@datadog/browser-rum';
import { IntercomProvider } from 'react-use-intercom';

import { ContextProvider } from '@context/ContextProvider';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import canvasTheme from "./theme";
import "@fonts/CeraPro.css";

import { version } from './version';
const APP_VERSION = version;

const CANVAS_ENV = process.env.REACT_APP_CANVAS_ENV || '';
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

datadogRum.init({
  applicationId: 'b4bdedf8-3b58-4dd8-8f43-39341e3f7cca',
  clientToken: 'pubf2bbbcbc1589e7824b78982eb2094e9b',
  site: 'datadoghq.eu',
  service: 'prime-token',
  version: APP_VERSION,
  env: CANVAS_ENV,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});
datadogRum.startSessionReplayRecording();

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <ThemeProvider theme={canvasTheme}>
          <CssBaseline />
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <Router />
          </IntercomProvider>
        </ThemeProvider>
      </ContextProvider>
    </div>
  );
}

export default App;
