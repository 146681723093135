import { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { DrawerLayout } from "./Drawer";
import { AppBarLayout } from "./AppBar";

const drawerWidth = 264;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: 45,
    },
    padding: theme.spacing(4),
    height: "100vh",
    minHeight: "100vh",
    background: "#F4F2F0",
    overflowY: "scroll",
    scrollbarColor: "rgba(128, 129, 145, 0.5) #fff",
    scrollbarWidth: "thin",
    flexGrow: 1
  },
}));

export const AppLayout = (props: any) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarLayout mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} drawerWidth={drawerWidth} />
      <DrawerLayout mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} drawerWidth={drawerWidth} />
      <main className={classes.content}>
        { props.children }
      </main>
    </div>
  );
}