import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Home } from "@pages/Home/Home";
import { SignIn } from "@pages/SignIn/SignIn";
import { Overview } from "@pages/Overview/Overview";
import { Billing } from "@pages/Billing/Billing";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/signin"} element={<SignIn />} />
        <Route path={"/overview"} element={<Overview />} />
        <Route path={"/billing/:pageId"} element={<Billing />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
