import { CircularProgress, createStyles, makeStyles, Typography } from "@material-ui/core";

import canvasLogo from "@assets/logos/canvas-logo.svg";

const useStyles = makeStyles(() =>
  createStyles({
    logoTitle: {
      color: '#fff',
      textTransform: 'uppercase',
      fontWeight: 500,
      '& span': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontWeight: 300
      }
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 30,
      '& img': {
        height: 50,
        width: 70,
        marginRight: 13,
      },
    },
    mainLoaderWrapper: {
      minHeight: '100vh',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#333042',
      zIndex: 2000,
      position: 'relative',
    },
    progress: {
      paddingTop: '2px'
    },

  }),
);

export const MainPageLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainLoaderWrapper}>
      <div className={classes.logoWrapper}>
        <div><img src={canvasLogo} alt="" /></div>
        <Typography variant="h1" className={classes.logoTitle}>CANVAS <span>Prime</span></Typography>
      </div>
      <div className={classes.progress}>
        <CircularProgress color="primary" size={35} />
      </div>
    </div>
  );
};
