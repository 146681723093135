import { useState } from "react";
import { getSubsciptionLink } from "@services/billing.service";

import { CanvasButton } from "@shared/UI/CanvasButton";

import { makeStyles, Paper, Grid } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

const BILLING_REDIRECT_URL = process.env.REACT_APP_BILLING_REDIRECT_URL || '';

const PLAN_BASIC = 'basic';
const PLAN_METERED = 'metered';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block'
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(6)
  }
}));

export const Pricing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState('');

  const doSubscription = (priceId: string) => {
    setIsLoading(true);
    setPriceLoading(priceId);
    getSubsciptionLink(priceId, BILLING_REDIRECT_URL)
      .then(link => {
        // setIsLoading(false);
        window.location.href = link;
      })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
        setPriceLoading('');
      });
  };

  const doContact = () => {
    window.location.href = 'mailto:contact@canvas.co?Subject=API+Enterprise';
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.subtitle}>Subscription Plans</h2>
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <Paper className={classes.paper}>
            <h3>API Basic</h3>
            <h5>$200 / month</h5>
            <CanvasButton disabled={isLoading} loading={priceLoading===PLAN_BASIC} onClick={() => doSubscription(PLAN_BASIC)} >Basic Plan</CanvasButton>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper className={classes.paper}>
            <h3>API Metered</h3>
            <h5>$0.001 / unit</h5>
            <CanvasButton disabled={isLoading} loading={priceLoading===PLAN_METERED} onClick={() => doSubscription(PLAN_METERED)} >Metered Plan</CanvasButton>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper className={classes.paper}>
          <h3>API Enterprise</h3>
            <h5>unlimited</h5>
            <CanvasButton disabled={isLoading} loading={false} onClick={() => doContact()} >Contact Us</CanvasButton>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
