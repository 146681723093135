import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ulid } from "ulid";

import useQuery from "@hooks/useQuery";
import useAuthStore from "@hooks/useAuthStore";
import { useEffectOnce } from "@hooks/useEffectOnce";
import { exchangeCode, getLoginLink } from "@services/user.service";

import { makeStyles, Theme, Typography, CircularProgress, Button } from "@material-ui/core";

import { Toaster } from "@shared/Alert/Toaster";
import { CanvasButton } from "@shared/UI/CanvasButton";
import canvasLogo from "@assets/logos/canvas-logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#333042',
    zIndex: 2000,
    position: 'relative',
  },
  logoTitle: {
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 500,
    '& span': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 300
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
    '& img': {
      height: 50,
      width: 70,
      marginRight: 13,
    },
  },
  progress: {
    paddingTop: '2px'
  },
  buttons: {
    display: 'block'
  },
  btnLogin: {
    width: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  btnSignUp: {
    width: 120,
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 300,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
}),
);

export const SignIn = () => {
  const { isAuthorized, setIdToken, setAccessToken, setRefreshToken } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();

  const newUniqueId = () => {
    const uid = ulid();
    localStorage.setItem("uniqueId", uid);
    return uid;
  };

  const handleLogin = () => {
    setIsLoading(true);
    const uid = newUniqueId();
    getLoginLink(uid).then(link => {
      // setIsLoading(false);
      window.location.href = link;
    })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSignUp = () => {
    setIsLoading(true);
    const uid = newUniqueId();
    getLoginLink(uid).then(link => {
      // setIsLoading(false);
      window.location.href = link + '&screen_hint=signup';
    })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffectOnce(() => {
    if (isAuthorized) {
      setIsLoading(false);
      navigate("/overview");
    } else {
      const code = query.get("code");
      const state = query.get("state");
      const uniqueId = localStorage.getItem("uniqueId");
      if (code && (state === uniqueId)) {
        setIsLoading(true);
        const finalCode = Array.isArray(code) ? code[0] : code;
        // console.log('finalCode', finalCode);
        exchangeCode(finalCode).then((data) => {
          // console.log('exchangeCode', data);
          if (data) {
            setIdToken(data.id_token);
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setIsLoading(false);
            navigate("/overview");
          }
        }).catch((e: any) => {
          setIsLoading(false);
          console.log('exchangeCode', e);
        });
      } else {
        setIsLoading(false);
      }
    }
  });

  const error = query.get('error');
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoWrapper}>
        <div><img src={canvasLogo} alt="" /></div>
        <Typography variant="h1" className={classes.logoTitle}>CANVAS <span>Prime</span></Typography>
      </div>
      {isLoading && (
        <div className={classes.progress}>
          <CircularProgress color="primary" size={35} />
        </div>
      )}
      {!isLoading && (
        <div className={classes.buttons}>
          <CanvasButton onClick={handleLogin} loading={isLoading} size="medium" className={classes.btnLogin}>Login</CanvasButton>
          <Button onClick={handleSignUp} disabled={isLoading} size="medium" className={classes.btnSignUp}>Sign Up</Button>
        </div>
      )}
      {(error === '401') && (
        <Toaster severity="error" message="Your Session has timed out. Please login again." />
      )}
      {(error === '403') && (
        <Toaster severity="error" message="Your login is not Authorized to use this application. Please login again, or contact support." />
      )}
    </div>
  );
}