import { AuthProvider } from './AuthProvider';

export const ContextProvider = (props: any) => {
  const { children } = props;
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  );
};
