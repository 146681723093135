import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSubscriptionDetails } from "@services/billing.service";
import { useEffectOnce } from "@hooks/useEffectOnce";
import useAuthStore from "@hooks/useAuthStore";

import { AppLayout } from '@shared/AppLayout/AppLayout';
import { Status } from "./Status";
import { Pricing } from "./Pricing";
import { Success } from "./Success";
// import { Cancel } from "./Cancel";

import { makeStyles, Grid } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(6)
  }
}));

export const Billing = () => {
  const { pageId } = useParams();
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);


  useEffectOnce(() => {
    setIsLoading(true);
    getSubscriptionDetails()
      .then((details: any) => {
        console.log(details);
        if (details.status) {
          setDetails(details.data);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
      });
  });

  const classes = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.subtitle}>Billing &amp; Subscriptions</h1>

        <Grid container spacing={3}>
          <Grid item sm={4}>
            <Status details={details} detailsLoading={isLoading} />
          </Grid>
        </Grid>

        {(pageId === 'pricing') && (
          <Pricing />
        )}
        {(pageId === 'success') && (
          <Success />
        )}
        {(pageId === 'cancel') && (
          <Pricing />
        )}
      </div>
    </AppLayout>
  );
};
