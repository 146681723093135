import { useState, useEffect } from 'react';

import { makeStyles, Theme, Snackbar, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
  }
}));

export const Toaster = (props: any) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (props.message) {
      setOpen(true);
    }
  }, [])
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}>
      <Alert severity={props.severity} >{props.message}</Alert>
    </Snackbar>
  );
}


// import CloseIcon from '@material-ui/icons/Close';
// action={<IconButton color="inherit" onClick={handleClose}><CloseIcon /></IconButton>}
