import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

const colors = {
  mainOrange: '#F37222',
  navy: '#333042',
  red: '#FA4439',

};

const typoGeneral = {
  fontFamily: '"Cera Pro", sans-serif',
  color: '#333042',
};

const canvasTheme = createMuiTheme({
  typography: {
    fontFamily: '"Cera Pro", sans-serif',
  },
  palette: {
    primary: {
      light: '#f58e4e',
      main: '#F37222',
      dark: '#F37222',
      contrastText: '#fff',
    },
    secondary: {
      light: '#333042',
      main: '#333042',
      dark: '#333042',
      contrastText: '#fff',
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: colors.navy,
        color: '#fff',
      },
    },
    MuiDrawer: {
      paper: {
        background: colors.navy,
        color: '#fff',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#F37222',
      },
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#F37222',
        '&$checked': {
          color: '#F37222',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        fontFamily: '"Cera Pro", sans-serif',
        padding: '15.5px 20px',
        "&::placeholder": {
          color: "#333042",
        },
      },
      root: {
        background: '#F4F2F0'
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        height: 44,
        boxShadow: 'none',
        fontFamily: '"Cera Pro", sans-serif',
      },
      outlined: {
        color: '#333042'
      },
      outlinedSecondary: {
        color: '#fff',
        border: '1px solid #fff',
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: '#fff',
        },
      },
      contained: {
        boxShadow: 'none',
      },
      sizeLarge: {
        borderRadius: 8,
        height: 56,
      },
      label: {
        textTransform: 'capitalize',
        // color: '#fff',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: '"Cera Pro", sans-serif',
      },
      colorPrimary: {
        ...typoGeneral
      },
      colorSecondary: {
        color: '#F37222',
      },
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
        ...typoGeneral
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: 500,
        ...typoGeneral
      },
      h3: {
        fontSize: '1.375rem',
        fontWeight: 500,
        ...typoGeneral
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 500,
        ...typoGeneral
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: 500,
        ...typoGeneral
      },
      subtitle1: {
        ...typoGeneral,
        color: 'rgba(128, 129, 145, 1)',
        fontSize: '1.125rem',
        fontWeight: 500,
      },
      subtitle2: {
        ...typoGeneral,
        color: 'rgba(128, 129, 145, 1)',
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
  },
});

export default canvasTheme;
