import api from "./api";

const request = api("billing");

export const getSubscriptionDetails = async (): Promise<string> => {
  const data = await request('subscription', 'get', {});
  // console.log('getSubscriptionDetails', data);
  return data;
};

export const getSubsciptionLink = async (price: string, callback_url: string): Promise<string> => {
  const { link } = await request('subscription', 'post', {}, { price, callback_url });
  // console.log('getSubsciptionLink', link);
  return link;
};

export const getPortalLink = async (callback_url: string): Promise<string> => {
  const { link } = await request('portal', 'post', {}, { callback_url });
  // console.log('getPortalLink', link);
  return link;
};
