import { useEffect } from 'react';

import useAuthStore from "@hooks/useAuthStore";
import { useNavigate } from "react-router-dom";

import { AppLayout } from '@shared/AppLayout/AppLayout';
import { CodeBox } from '@shared/UI/CodeBox';

import { makeStyles, Paper, Grid } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

import { AddWalletResponse } from './responses/add-wallet.response.js';
import { ListWalletsResponse } from './responses/list-wallets.response.js';
import { RefreshBalanceResponse } from './responses/refresh-balance.response.js';
import { PollRefreshResponse } from './responses/poll-refresh.response.js';
import { GetBalanceResponse } from './responses/get-balance.response.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
  },
  code: {
    fontFamily: '"Roboto Mono"',
    width: '100%',
    height: '360px'
  },
  title: {
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(0.5),
  },
  text: {
    marginBlockStart: 0,
    marginBlockEnd: theme.spacing(2),
  },
  label: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingBottom: theme.spacing(0.5),
    textTransform: 'uppercase',
    fontFamily: '"Roboto Mono"',
    fontWeight: 400,
    color: '#666'
  }
}));

const RequestResponse = (props: any) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <h5 className={classes.label}>Request</h5>
        <CodeBox code={props.request} wrap={false} />
      </Grid>
      <Grid item md={6}>
        <h5 className={classes.label}>Response</h5>
        <CodeBox code={props.response} wrap={false} />
      </Grid>
    </Grid>
  );
}
export const Overview = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const idToken = localStorage.getItem("idToken") || '';
  const accessToken = localStorage.getItem("accessToken") || '';
  const refreshToken = localStorage.getItem("refreshToken") || '';
  const url = process.env.REACT_APP_BASE_API_URL || '';
  const curl = `curl -X`;
  const header = `-H 'accept: application/json'`;
  const authorization = `-H 'Authorization: Bearer ${accessToken}'`;
  const wallet = '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045';

  const addWalletRequest = `${curl} POST ${url}/wallets/addresses \\\n  -d '{"address":"${wallet}"}' \\\n  -H 'content-type: application/json' \\\n  ${header} \\\n  ${authorization}`;
  const listWalletsRequest = `${curl} GET ${url}/wallets/addresses \\\n  ${header} \\\n  ${authorization}`;
  const refreshPortfolioRequest = `${curl} POST ${url}/wallets/balances/refresh/all \\\n  ${header} \\\n  ${authorization}`;
  const pollRefreshRequest = `${curl} GET ${url}/wallets/balances/refresh/{refreshId} \\\n  ${header} \\\n  ${authorization}`;
  const getBalanceRequest = `${curl} GET ${url}/wallets/balances \\\n  ${header} \\\n  ${authorization}`;
  const refreshTokenRequest = `${curl} PUT ${url}/users/auth \\\n  -d '{"refresh_token":"${refreshToken}"}' \\\n  -H 'content-type: application/json' \\\n  ${header}`;
  const RefreshTokenResponse = `{\n  "id_token": "${idToken}",\n  "access_token": "${accessToken}"\n}`;
  const classes = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <h1>Your API Tokens</h1>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <h5 className={classes.label}>Access Token</h5>
              <CodeBox code={accessToken} wrap={true} />
            </Grid>
            <Grid item md={6}>
              <h5 className={classes.label}>Refresh Token</h5>
              <CodeBox code={refreshToken} wrap={true} />
            </Grid>
          </Grid>
          <h2>Getting Started</h2>
          <h4 className={classes.title}>1. Add an ethereum wallet address to your portfolio</h4>
          <p className={classes.text}>The first step is to add a wallet address to your portfolio.</p>
          <RequestResponse request={addWalletRequest} response={AddWalletResponse} />
          <h4 className={classes.title}>2. List wallet addresses in your portfolio</h4>
          <p className={classes.text}>List the addresses to ensure that your wallet address has been added to your portfolio</p>
          <RequestResponse request={listWalletsRequest} response={ListWalletsResponse} />
          <h4 className={classes.title}>3. Trigger a Balance Refresh of entire Portfolio</h4>
          <p className={classes.text}>For the first time you can trigger an immediate 'refresh' of your portfolio balance. This will occur automatically from now on.</p>
          <RequestResponse request={refreshPortfolioRequest} response={RefreshBalanceResponse} />
          <h4 className={classes.title}>4. Poll Refresh Status until Complete</h4>
          <p className={classes.text}>Using the 'refreshId' output from the above command, you can request a status update on your refresh task.</p>
          <RequestResponse request={pollRefreshRequest} response={PollRefreshResponse} />
          <h4 className={classes.title}>6. Get Porfolio Balance</h4>
          <p className={classes.text}> Once the refresh task is 'COMPLETED', request a full balance of your portfolio.</p>
          <RequestResponse request={getBalanceRequest} response={GetBalanceResponse} />
          <h4 className={classes.title}>7. Refresh your Token</h4>
          <p className={classes.text}> Your 'access token' will last for 24hrs. To grab a new one, use your 'refresh token':</p>
          <RequestResponse request={refreshTokenRequest} response={RefreshTokenResponse} />
        </Paper>
      </div>
    </AppLayout>
  );
}